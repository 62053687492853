import { Injectable, NgZone } from '@angular/core';
import { DesignType } from '@profis-engineering/pe-ui-common/entities/code-lists/design-type';
import { Design, IBaseDesign } from '@profis-engineering/pe-ui-common/entities/design';
import { DisplayDesignType } from '@profis-engineering/pe-ui-common/entities/display-design';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import {
    AppSettings, AppSettingsSection, IDesignInfo, IDesignListInfo, IFavoritesInfo,
    IImportDesignProvider, IQuickStartApplication, IUserSettingsInfo, UserSettingsSection
} from '@profis-engineering/pe-ui-common/entities/module-initial-data';
import {
    ISaveDesign, ISaveDesignResult
} from '@profis-engineering/pe-ui-common/entities/save-design';
import { AddEditType } from '@profis-engineering/pe-ui-common/enums/add-edit-type';
import {
    DesignTemplateEntity
} from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.Entities.DesignTemplate';
import {
    ApplicationSettingsDisplayType
} from '@profis-engineering/pe-ui-common/helpers/app-settings-helper';
import { getModuleOrder } from '@profis-engineering/pe-ui-common/helpers/module-helper';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { IDesignListItem } from '@profis-engineering/pe-ui-common/services/document.common';

import { getSpriteAsIconStyle } from '../sprites';
import {
    ApplicationSettingsPunch, ApplicationSettingsStrength, AppSettingsService
} from './app-settings.service';
import { DataService } from './data.service';
import {
    DesignDetails, DesignService, DesignTypeId, designTypes, designTypesById
} from './design.service';
import { DocumentService } from './document.service';
import { FavoritesService } from './favorites.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { ImportDesignProviderService } from './import-design-provider.service';
import { LocalizationService } from './localization.service';
import { ModalService } from './modal.service';
import { RoutingService } from './routing.service';
import { TrackingDetails, TrackingService } from './tracking.service';
import { UserSettingsService, UserSettingsSP } from './user-settings.service';
import { UserService } from './user.service';

const moduleIndex = 3;
const StrengthFeatureKey = 'SP_ContentFeature_Strength';
const PunchFeatureKey = 'SP_ContentFeature_Punch';

@Injectable({
    providedIn: 'root'
})
export class ApplicationProviderService {
    constructor(
        private readonly localizationService: LocalizationService,
        private readonly userSettingsService: UserSettingsService,
        private readonly appSettings: AppSettingsService,
        private readonly designService: DesignService,
        private readonly modalService: ModalService,
        private readonly favoritesService: FavoritesService,
        private readonly documentService: DocumentService,
        private readonly dataService: DataService,
        private readonly userService: UserService,
        private readonly routingService: RoutingService,
        private readonly importDesignProvider: ImportDesignProviderService,
        private readonly featureVisibilityService: FeatureVisibilityService,
        private readonly trackingService: TrackingService,
        private readonly ngZone: NgZone,
    ) { }

    /** This is a temporary method to update design type translations (present on UI where module designs are listed).
     * We should remove this after proper support for this is added to common code (pe-ui) */
    public updateDesignTypeTranslations() {
        for (const designType of this.designTypes ?? []) {
            designType.name = this.localizationService.getString(designTypesById[designType.id as DesignTypeId].nameKey);
        }
    }

    /** design list section name */
    private designTypes?: DesignType[];
    public getDesignTypes(): DesignType[] {
        this.designTypes ??= Object.values(designTypes).map(designType => new DesignType({
            id: designType.id,
            nameResourceKey: designType.nameKey,
            name: this.localizationService.getString(designType.nameKey)
        }));

        return this.designTypes;
    }

    /** quick start settings */
    public getAppSettings(): AppSettings[] {
        const appSettingsSections: AppSettingsSection[] = [
            this.getStrengthAppSettingsSection() as AppSettingsSection,
            this.getPunchAppSettingsSection() as AppSettingsSection
        ].filter(x => x != null);

        return appSettingsSections.length > 0
            ? [
                {
                    order: getModuleOrder(moduleIndex, 0),
                    name: 'app-settings-sp-module',
                    collapsed: false,
                    titleKey: 'SP.QuickStartSettings.ShearDesignSection',
                    title: this.localizationService.getString('SP.QuickStartSettings.ShearDesignSection'),
                    tooltipKey: 'Common.ShowHideSection.Tooltip',
                    sections: appSettingsSections
                }
            ]
            : [];
    }

    public getStrengthAppSettingsSection(): AppSettingsSection<ApplicationSettingsStrength> | undefined {
        return this.featureVisibilityService.isFeatureEnabled(StrengthFeatureKey)
            ? {
                id: 'application-settings-sp-strength',
                titleKey: 'SP.QuickStartSettings.ShearDesignSection.Strength',
                title: this.localizationService.getString('SP.QuickStartSettings.ShearDesignSection.Strength'),
                type: designTypes.strength.id as ApplicationSettingsDisplayType,
                componentTagName: 'sp-app-settings-strength',

                loadSettings: () => this.appSettings.loadStrengthSettings(),
                handleRegionChange: (data, regionId) => this.appSettings.handleStrengthRegionChange(data, regionId),
                updateSettings: data => this.appSettings.updateStrengthSettings(data)
            }
            : undefined;
    }

    public getPunchAppSettingsSection(): AppSettingsSection<ApplicationSettingsPunch> | undefined {
        return this.featureVisibilityService.isFeatureEnabled(PunchFeatureKey)
            ? {
                id: 'application-settings-sp-punch',
                titleKey: 'SP.QuickStartSettings.ShearDesignSection.Punch',
                title: this.localizationService.getString('SP.QuickStartSettings.ShearDesignSection.Punch'),
                type: designTypes.punch.id as ApplicationSettingsDisplayType,
                componentTagName: 'sp-app-settings-punch',

                loadSettings: () => this.appSettings.loadPunchSettings(),
                handleRegionChange: (data, regionId) => this.appSettings.handlePunchRegionChange(data, regionId),
                updateSettings: data => this.appSettings.updatePunchSettings(data)
            }
            : undefined;
    }

    /** quick start buttons */
    public getQuickStartApplications(): IQuickStartApplication[] {
        return [
            this.getPunchQuickStartApplication()!,
            this.getStrengthQuickStartApplication()!
        ].filter(x => x != null);
    }

    public getStrengthQuickStartApplication(): IQuickStartApplication | undefined {
        const isNewHomePageEnabled = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');

        return this.featureVisibilityService.isFeatureEnabled(StrengthFeatureKey)
            ? {
                order: getModuleOrder(moduleIndex, 0),
                showInQuickStart: true,
                showInCollapsedState: false,

                designType: designTypes.strength.id,

                idQuickStart: 'quick-start-button-sp-strength',
                imageStyle: isNewHomePageEnabled
                    ? getSpriteAsIconStyle('sprite-module-strength-mid')
                    : getSpriteAsIconStyle('sprite-module-strength'),
                // TODO new home page: rename sprite-module-strength-mid to sprite-module-strength and remove switch when new home page is deployed
                // actionTranslationKey is of no use in new home page - remove it when new home page deployment
                actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.Custom.Action',
                designTypeTranslationKey: 'SP.QuickStartButtons.Strength.DesignType',
                designTypeTranslation: this.localizationService.getString('SP.QuickStartButtons.Strength.DesignType'),
                newLabelTranslationKey: 'Common.QuickStartButtons.NewStatus',

                isEnabled: () => this.dataService.strengthRegionsById[this.userSettingsService.settings.sp.quickStart.strength.resolvedRegionId ?? 0] != null,
                isDesignTypeDisabled: () => false,
                getButtonTooltip: () => undefined,
                createRegionDesignStandardApprovalNumber: () => this.designService.createRegionDesignStandardApprovalNumber(this.userSettingsService.settings.sp.quickStart.strength.resolvedRegionId ?? 0, this.userSettingsService.settings.sp.quickStart.strength.designStandardId.value),
                showNewLabel: () => this.dataService.strengthNewLabelRegionsById[this.userSettingsService.settings.sp.quickStart.strength.resolvedRegionId ?? 0] != null,
                getNewDesignName: () => this.designService.getNewStrengthDesignName(),
                createNewDesign: async (saveDesign) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(async () => {
                        // call create design on the next frame
                        // this way UI can update before we start creating design
                        // that can take some time with WASM
                        await new Promise<void>(resolve => setTimeout(() => resolve()));

                        return await this.createNewStrengthQuickStartDesign(saveDesign);
                    });
                }
            }
            : undefined;
    }

    public getPunchQuickStartApplication(): IQuickStartApplication | undefined {
        const isNewHomePageEnabled = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');

        return this.featureVisibilityService.isFeatureEnabled(PunchFeatureKey)
            ? {
                order: getModuleOrder(moduleIndex, 1),
                showInQuickStart: true,
                showInCollapsedState: false,

                designType: designTypes.punch.id,

                idQuickStart: 'quick-start-button-sp-punch',
                imageStyle: isNewHomePageEnabled
                    ? getSpriteAsIconStyle('sprite-module-punch-mid')
                    : getSpriteAsIconStyle('sprite-module-punch'),
                // TODO new home page: rename sprite-module-punch-mid to sprite-module-punch and remove switch when new home page is deployed
                // actionTranslationKey is of no use in new home page - remove it when new home page deployment
                actionTranslationKey: 'Agito.Hilti.Profis3.ProjectAndDesing.Main.Custom.Action',
                designTypeTranslationKey: 'SP.QuickStartButtons.Punch.DesignType',
                designTypeTranslation: this.localizationService.getString('SP.QuickStartButtons.Punch.DesignType'),
                newLabelTranslationKey: 'Common.QuickStartButtons.NewStatus',

                isEnabled: () => this.dataService.punchRegionsById[this.userSettingsService.settings.sp.quickStart.punch.resolvedRegionId ?? 0] != null,
                isDesignTypeDisabled: () => false,
                getButtonTooltip: () => undefined,
                createRegionDesignStandardApprovalNumber: () => this.designService.createRegionDesignStandardApprovalNumber(this.userSettingsService.settings.sp.quickStart.punch.resolvedRegionId ?? 0, this.userSettingsService.settings.sp.quickStart.punch.designStandardId.value),
                showNewLabel: () => this.dataService.punchNewLabelRegionsById[this.userSettingsService.settings.sp.quickStart.punch.resolvedRegionId ?? 0] != null,
                getNewDesignName: () => this.designService.getNewPunchDesignName(),
                createNewDesign: async (saveDesign) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(async () => {
                        // call create design on the next frame
                        // this way UI can update before we start creating design
                        // that can take some time with WASM
                        await new Promise<void>(resolve => setTimeout(() => resolve()));

                        return await this.createNewPunchQuickStartDesign(saveDesign);
                    });
                }
            }
            : undefined;
    }

    /** design list */
    public getDesignListInfo(): IDesignListInfo[] {
        return [
            this.getStrengthDesignListInfo()!,
            this.getPunchDesignListInfo()!
        ].filter(x => x != null);
    }

    public getStrengthDesignListInfo(): IDesignListInfo | undefined {
        const isNewHomePageEnabled = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');

        return this.featureVisibilityService.isFeatureEnabled(StrengthFeatureKey)
            ? {
                order: getModuleOrder(moduleIndex, 0),
                designTypeId: designTypes.strength.id,
                designTypeName: this.localizationService.getString('SP.DesignList.Strength.DesignType'),
                designTypeImage: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-model3d-strength-mid') : getSpriteAsIconStyle('sprite-model3d-strength'),
                // TODO new home page: rename sprite-model3d-strength-mid to sprite-model3d-strength and remove switch when new home page is deployed

                isEnabled: () => true,
                isBulkReportEnabled: () => false,
                checkDesign: (design: IBaseDesign) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.checkDesign(design));
                },
                openFromDocumentDesign: (documentDesign: IDesignListItem) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.openFromDocumentDesign(documentDesign));
                },
                openDesignSettings: async (designId: string, _designName: string, _regionId: number, onDesignSettingsClosed?: () => void) => {
                    NgZone.assertNotInAngularZone();
                    await this.ngZone.run(() => this.openDesignSettings(designId, onDesignSettingsClosed));
                },
                openTemplate: (templateId: string) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.openTemplate(templateId));
                },
                openTemplateSettings: async (templateId: string, onTemplateSettingsClosed?: () => void) => {
                    NgZone.assertNotInAngularZone();
                    await this.ngZone.run(() => this.openTemplateSettings(templateId, onTemplateSettingsClosed));
                },
                newDesignFromTemplate: (templateId, projectId, designName) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.newDesignFromTemplate(templateId, projectId, designName));
                },
                getDesign: (designId: string) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.getDesign(designId));
                },
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design, isNewHomePageEnabled ? 'sprite-model3d-strength-mid' : 'sprite-model3d-strength', getDesignThumbnail),
                // TODO new home page: rename sprite-model3d-strength-mid to sprite-model3d-strength and remove switch when new home page is deployed
                toDisplayDesignTemplate: (template: DesignTemplateEntity, getDesignThumbnail?: (templateId: string) => string) => this.designService.toDisplayDesignTemplate(template, isNewHomePageEnabled ? 'sprite-model3d-strength-mid' : 'sprite-model3d-strength', getDesignThumbnail),
                // TODO new home page: rename sprite-model3d-strength-mid to sprite-model3d-strength and remove switch when new home page is deployed
                getProjectDesignFromDesign: (design: Design) => design.projectDesign as object

            }
            : undefined;
    }

    public getPunchDesignListInfo(): IDesignListInfo | undefined {
        const isNewHomePageEnabled = this.featureVisibilityService.isFeatureEnabled('PE_EnableNewHomePage');

        return this.featureVisibilityService.isFeatureEnabled(PunchFeatureKey)
            ? {
                order: getModuleOrder(moduleIndex, 1),
                designTypeId: designTypes.punch.id,
                designTypeName: this.localizationService.getString('SP.DesignList.Punch.DesignType'),
                designTypeImage: isNewHomePageEnabled ? getSpriteAsIconStyle('sprite-model3d-punch-mid') : getSpriteAsIconStyle('sprite-model3d-punch'),
                // TODO new home page: rename sprite-model3d-punch-mid to sprite-model3d-punch and remove switch when new home page is deployed

                isEnabled: () => true,
                isBulkReportEnabled: () => false,
                checkDesign: (design: IBaseDesign) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.checkDesign(design));
                },
                openFromDocumentDesign: (documentDesign: IDesignListItem) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.openFromDocumentDesign(documentDesign));
                },
                openDesignSettings: async (designId: string, _designName: string, _regionId: number, onDesignSettingsClosed?: () => void) => {
                    NgZone.assertNotInAngularZone();
                    await this.ngZone.run(() => this.openDesignSettings(designId, onDesignSettingsClosed));
                },
                openTemplate: (templateId: string) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.openTemplate(templateId));
                },
                openTemplateSettings: async (templateId: string, onTemplateSettingsClosed?: () => void) => {
                    NgZone.assertNotInAngularZone();
                    await this.ngZone.run(() => this.openTemplateSettings(templateId, onTemplateSettingsClosed));
                },
                newDesignFromTemplate: (templateId, projectId, designName) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.newDesignFromTemplate(templateId, projectId, designName));
                },
                getDesign: (designId: string) => {
                    NgZone.assertNotInAngularZone();
                    return this.ngZone.run(() => this.getDesign(designId));
                },
                toDisplayDesign: (design: IDesignListItem, getDesignThumbnail?: (designId: string) => string) => this.designService.toDisplayDesign(design, isNewHomePageEnabled ? 'sprite-model3d-punch-mid' : 'sprite-model3d-punch', getDesignThumbnail),
                // TODO new home page: rename sprite-model3d-punch-mid to sprite-model3d-punch and remove switch when new home page is deployed
                toDisplayDesignTemplate: (template: DesignTemplateEntity, getDesignThumbnail?: (templateId: string) => string) => this.designService.toDisplayDesignTemplate(template, isNewHomePageEnabled ? 'sprite-model3d-punch-mid' : 'sprite-model3d-punch', getDesignThumbnail),
                // TODO new home page: rename sprite-model3d-punch-mid to sprite-model3d-punch and remove switch when new home page is deployed
                getProjectDesignFromDesign: (design: Design) => design.projectDesign as object
            }
            : undefined;
    }

    /** add edit design */
    public getDesignInfo(designName?: string): IDesignInfo[] {
        return [
            this.getStrengthDesignInfo(designName)!,
            this.getPunchDesignInfo(designName)!
        ].filter(x => x != null);
    }

    public getStrengthDesignInfo(designName?: string): IDesignInfo | undefined {
        return this.featureVisibilityService.isFeatureEnabled(StrengthFeatureKey)
            ? {
                order: getModuleOrder(moduleIndex, 0),
                id: 'add-edit-design-sp-strength',
                designTypeId: designTypes.strength.id,
                titleTranslationKey: 'SP.AddEditDesign.Strength',
                title: this.localizationService.getString('SP.AddEditDesign.Strength'),
                mainTagName: 'sp-main',
                componentTagName: 'sp-add-edit-design-strength',
                imageStyle: () => getSpriteAsIconStyle('sprite-module-custom-strength'),
                availableRegions: this.dataService.strengthRegions.map(x => x.id),
                isEnabled: () => true,
                isAvailable: regionId => this.dataService.strengthRegionsById[regionId] != null,
                getDesignName: () => designName ?? this.designService.getNewStrengthDesignName()
            }
            : undefined;
    }

    public getPunchDesignInfo(designName?: string): IDesignInfo | undefined {
        return this.featureVisibilityService.isFeatureEnabled(PunchFeatureKey)
            ? {
                order: getModuleOrder(moduleIndex, 1),
                id: 'add-edit-design-sp-punch',
                designTypeId: designTypes.punch.id,
                titleTranslationKey: 'SP.AddEditDesign.Punch',
                title: this.localizationService.getString('SP.AddEditDesign.Punch'),
                mainTagName: 'sp-main',
                componentTagName: 'sp-add-edit-design-punch',
                imageStyle: () => getSpriteAsIconStyle('sprite-module-custom-punch'),
                availableRegions: this.dataService.punchRegions.map(x => x.id),
                isEnabled: () => true,
                isAvailable: regionId => this.dataService.punchRegionsById[regionId] != null,
                getDesignName: () => designName ?? this.designService.getNewPunchDesignName()
            }
            : undefined;
    }

    /** favorites id */
    public getFavoritesInfo(): IFavoritesInfo {
        return {
            getFavoritesType: (menuType, designType) => this.favoritesService.getFavoritesType(menuType, designType),
            getMenuRegionIdFromFavorites: (id, designType) => this.favoritesService.getMenuRegionIdFavorites(id, designType)
        };
    }

    /** user settings definition */
    public getUserSettingsInfo(): IUserSettingsInfo {
        return {
            userSettingsKey: 'sp',
            userSettings: new UserSettingsSP(
                this.dataService,
                this.userSettingsService
            ) as unknown as UserSettingsSection
        };
    }

    /** project page => design import */
    public getImportDesignProvider(): IImportDesignProvider {
        return this.importDesignProvider;
    }

    /** called when design is opened from url */
    private async checkDesign(design: IBaseDesign): Promise<ICalculationResult | undefined> {
        // closeDesign will be called by main.component
        const result = await this.designService.openDesign({
            designId: design.id
        });

        if (result == null) {
            return undefined;
        }

        const peDesignObject = this.designService.createPeDesignObject(result.designDetails, result.trackingDetails, result.convertChanges);

        return {
            design: peDesignObject
        } as ICalculationResult;
    }

    /** design list for templates => template settings */
    private async openTemplateSettings(templateId: string, onClosed?: () => void) {
        let designDetails: DesignDetails = undefined!;
        let trackingDetails: TrackingDetails = undefined!;

        try {
            // closeDesign is called in finally
            const result = await this.designService.openDesignTemplate({
                designTemplateId: templateId
            });

            if (result != null) {
                designDetails = result.designDetails;
                trackingDetails = result.trackingDetails;

                const designInfo = this.getDesignInfo()
                    .find(x => x.designTypeId == designDetails.designTypeId && x.isAvailable(designDetails.regionId));

                if (designInfo == undefined) {
                    throw new Error('Design info not found');
                }

                await this.modalService.openAddEditDesignFromModule({
                    design: {
                        id: designDetails.templateId,
                        name: designDetails.templateName,
                        region: designDetails.commonRegion,
                        designType: designDetails.designTypeId,
                        displayDesignType: DisplayDesignType.template,
                        designTemplateDocumentId: designDetails.templateId,
                        // TODO FILIP: do we need anchorName and approvalNumber?
                        anchorName: '',
                        approvalNumber: '',
                        projectDesign: designDetails.projectDesign,
                        design: this.designService.createPeDesignObject(designDetails, trackingDetails, result.convertChanges)
                    },
                    addEditType: AddEditType.edit,
                    selectedModuleDesignInfo: designInfo
                }).closed;
            }
        }
        finally {
            if (designDetails != null && trackingDetails != null) {
                // no need to await
                this.designService.closeDesignTemplate(designDetails, trackingDetails)
                    .catch((error: unknown) => console.error(error));
            }

            onClosed?.();
        }
    }

    /** design list => open */
    private async openFromDocumentDesign(documentDesign: IDesignListItem): Promise<ICalculationResult | undefined> {
        // TODO FILIP: do we need this?
        documentDesign.projectName = this.documentService.findProjectById(documentDesign.projectId).name ?? '';

        // closeDesign will be called by main.component
        const result = await this.designService.openDesign({
            designId: documentDesign.id
        });

        if (result == null) {
            return undefined;
        }

        const peDesignObject = this.designService.createPeDesignObject(result.designDetails, result.trackingDetails, result.convertChanges);
        this.userService.changeDesign(this.documentService.findProjectById(result.designDetails.projectId!), peDesignObject);

        // TODO TEAM: can we have a normal await?
        this.routingService.navigateToUrl(UrlPath.main + result.designDetails.designId!)
            .catch((error: unknown) => console.error(error));

        // not used in pe-ui
        return undefined as unknown as ICalculationResult;
    }

    /** design list => edit template */
    private async openTemplate(templateId: string): Promise<ISaveDesignResult | undefined> {
        try {
            const result = await this.designService.openDesignTemplate({
                designTemplateId: templateId
            });

            if (result == null) {
                return undefined;
            }

            const peDesignObject = this.designService.createPeDesignObject(result.designDetails, result.trackingDetails, result.convertChanges);

            this.userService.changeDesign(undefined, peDesignObject);

            // TODO TEAM: can we have a normal await?
            this.routingService.navigateToUrl(UrlPath.main + result.designDetails.templateId!)
                .catch((error: unknown) => console.error(error));

            return {
                designId: result.designDetails.templateId!,
                path: UrlPath.main + result.designDetails.templateId!,
                design: peDesignObject,
                success: true
            };
        }
        catch (error) {
            console.error(error);

            return {
                path: UrlPath.main,
                success: false,
                designId: undefined as unknown as string,
                design: undefined as unknown as Design
            };
        }
    }

    /** design list => create design file or box click */
    public async newDesignFromTemplate(templateId: string, projectId?: string, designName?: string): Promise<ISaveDesignResult | undefined> {
        try {
            const result = await this.designService.createDesignFromTemplate({
                templateId,
                projectId,
                designName
            });

            if (result == null) {
                return undefined;
            }

            const peDesignObject = this.designService.createPeDesignObject(result.designDetails, result.trackingDetails, result.convertChanges);
            this.userService.changeDesign(this.documentService.draftsProject, peDesignObject);

            // TODO TEAM: can we have a normal await?
            this.routingService.navigateToUrl(UrlPath.main + result.designDetails.designId!)
                .catch((error: unknown) => console.error(error));

            return {
                designId: result.designDetails.designId!,
                path: UrlPath.main + result.designDetails.designId!,
                design: peDesignObject,
                success: true
            };
        }
        catch (error) {
            console.error(error);

            return {
                path: UrlPath.main,
                success: false,
                designId: undefined as unknown as string,
                design: undefined as unknown as Design
            };
        }
    }

    /** design list => design settings */
    private async openDesignSettings(designId: string, onClosed?: () => void): Promise<void> {
        let designDetails: DesignDetails = undefined!;
        let trackingDetails: TrackingDetails = undefined!;

        try {
            // closeDesign is called in finally
            const result = await this.designService.openDesign({
                designId
            });

            if (result != null) {
                designDetails = result.designDetails;
                trackingDetails = result.trackingDetails;

                const designInfo = this.getDesignInfo()
                    .find(x => x.designTypeId == designDetails.designTypeId && x.isAvailable(designDetails.regionId));

                if (designInfo == undefined) {
                    throw new Error('Design info not found');
                }

                await this.modalService.openAddEditDesignFromModule({
                    design: {
                        id: designDetails.designId!,
                        name: designDetails.designName,
                        projectId: designDetails.projectId!,
                        projectName: designDetails.projectName,
                        region: designDetails.commonRegion,
                        designType: designDetails.designTypeId,
                        displayDesignType: DisplayDesignType.design,
                        // TODO FILIP: do we need anchorName and approvalNumber?
                        anchorName: '',
                        approvalNumber: '',
                        projectDesign: designDetails.projectDesign,
                        design: this.designService.createPeDesignObject(designDetails, trackingDetails, result.convertChanges)
                    },
                    addEditType: AddEditType.edit,
                    selectedModuleDesignInfo: designInfo
                }).closed;
            }
        }
        finally {
            if (designDetails != null && trackingDetails != null) {
                // no need to await
                this.designService.closeDesign(designDetails, trackingDetails)
                    .catch((error: unknown) => console.error(error));
            }

            onClosed?.();
        }
    }

    /** design list => drop on project => design name conflict */
    private async getDesign(designId: string): Promise<Design | undefined> {
        // only called by rename popup from pe-ui

        // pe-ui will call design.processDesignClose(false) when done renaming
        const result = await this.designService.openDesign({
            designId
        });

        if (result == null) {
            return undefined;
        }

        const peDesignObject = this.designService.createPeDesignObject(result.designDetails, result.trackingDetails, result.convertChanges);
        (peDesignObject as unknown as { processDesignClose: () => void }).processDesignClose = () => {
            // this is called only from pe-ui
            NgZone.assertNotInAngularZone();
            this.ngZone.run(() => {
                // document service close is already called by pe-ui
                this.trackingService.trackOnDesignClose(result.designDetails, result.trackingDetails)
                .catch((error: unknown) => console.error(error));
            });
        };

        return peDesignObject;
    }

    /** Quick start new design strength */
    private async createNewStrengthQuickStartDesign(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        try {
            const quickStart = this.userSettingsService.settings.sp.quickStart.strength;

            const languageId = this.localizationService.selectedLanguageLCID();
            const { designDetails, trackingDetails } = await this.designService.strengthCreateDesign({
                designName: saveDesign.designName,
                designStandardId: quickStart.designStandardId.value,
                designTypeId: designTypes.strength.id,
                projectId: saveDesign.projectId,
                regionId: quickStart.resolvedRegionId!,
                unitArea: quickStart.area.value,
                unitAreaPerLength: quickStart.areaPerLength.value,
                unitDensity: quickStart.density.value,
                unitForce: quickStart.force.value,
                unitForcePerLength: quickStart.forcePerLength.value,
                unitLength: quickStart.length.value,
                unitMoment: quickStart.moment.value,
                unitStress: quickStart.stress.value,
                unitTemperature: quickStart.temperature.value,
                reportLanguageId: languageId,

                // default values - null with placeholder text
                alphaCC: null,
                etaT: 1,
                e: null,
                gammaC: null,
                gammaS: null,
                Kc: null,
            });

            const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

            return {
                designId: designDetails.designId!,
                path: UrlPath.main,
                design: peDesignObject,
                success: true
            };
        }
        catch (error) {
            // pe-ui will not log any errors
            console.error(error);

            throw error;
        }
    }

    /** Quick start new design punch */
    private async createNewPunchQuickStartDesign(saveDesign: ISaveDesign): Promise<ISaveDesignResult> {
        try {
            const quickStart = this.userSettingsService.settings.sp.quickStart.punch;

            const languageId = this.localizationService.selectedLanguageLCID();
            // TODO FILIP: separate functions for strength and punch
            const { designDetails, trackingDetails } = await this.designService.punchCreateDesign({
                designName: saveDesign.designName,
                designStandardId: quickStart.designStandardId.value,
                designTypeId: designTypes.punch.id,
                projectId: saveDesign.projectId,
                regionId: quickStart.resolvedRegionId!,
                unitArea: quickStart.area.value,
                unitAreaPerLength: quickStart.areaPerLength.value,
                unitDensity: quickStart.density.value,
                unitForce: quickStart.force.value,
                unitForcePerLength: quickStart.forcePerLength.value,
                unitLength: quickStart.length.value,
                unitMoment: quickStart.moment.value,
                unitStress: quickStart.stress.value,
                unitTemperature: quickStart.temperature.value,
                reportLanguageId: languageId,

                // default values - null with placeholder text
                // TODO TEAM: add
                alphaCC: null,
                etaT: 1,
                e: null,
                gammaC: null,
                gammaS: null,
            });

            const peDesignObject = this.designService.createPeDesignObject(designDetails, trackingDetails);

            return {
                designId: designDetails.designId!,
                path: UrlPath.main,
                design: peDesignObject,
                success: true
            };
        }
        catch (error) {
            // pe-ui will not log any errors
            console.error(error);

            throw error;
        }
    }
}
