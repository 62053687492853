import { Injectable } from '@angular/core';
import { CodeList } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { CommonRegion } from '@profis-engineering/pe-ui-common/entities/code-lists/common-region';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { MarkRequired, PickKeys } from 'ts-essentials';
import { CommonCodeListService } from './common-code-list.service';
import { DesignTypeId } from './design.service';
import { FeatureVisibilityService } from './features-visibility.service';
import { LocalizationService } from './localization.service';
import { SpApiService } from './sp-api.service';

export interface AppData {
    propertyDetails: ApiAppPropertyDetails;
    regions: AppDataRegion[];
    designStandards: DesignStandard[];
    postInstalledReinforcementDesigns: PostInstalledReinforcementDesign[];
    loadTypes: LoadType[];
    baseMaterials: BaseMaterial[];
    aggregateSizes: AggregateSize[];
    etaTValues: number[];
    zonesNumbers: ZonesNumber[];
    openingsNumbers: OpeningsNumber[];
    drillingTypes: DrillingType[];
    holeTypes: HoleType[];
    drillingAids: DrillingAid[];
    installationDirections: InstallationDirection[];
    concreteMembers: ConcreteMember[];
    compressionMembers: CompressionMember[];
    baseMembers: BaseMember[];
    fastenerFamilyGroups: FastenerFamilyGroup[];
    fastenerFamilies: FastenerFamily[];
    fasteners: Fastener[];
    betaValues: Beta[];
    reinforcementArrangements: ReinforcementArrangement[];
    columnPositions: ColumnPosition[];
}

export type AppPropertyId = AppUnitPropertyId |
    'designStandardId' |
    'e' |
    'kc' |
    'etaT' |
    'gammaS' |
    'gammaC' |
    'alphaCC';

export type AppUnitPropertyId =
    'unitAngle' |
    'unitLength' |
    'unitArea' |
    'unitStress' |
    'unitForce' |
    'unitMoment' |
    'unitTemperature' |
    'unitForcePerLength' |
    'unitDensity' |
    'unitAreaPerLength';

export type PropertyDetailDefaultValue = MarkRequired<PropertyDetail, 'defaultValue'>;

/** property detail per property - some properties like units always have defaultValue so we remove the optional marker */
export type AppPropertyDetailMap = Pick<{
    'designStandardId': PropertyDetailDefaultValue;
    'e': PropertyDetail;
    'kc': PropertyDetail;
    'gammaS': PropertyDetail;
    'gammaC': PropertyDetail;
    'alphaCC': PropertyDetail;
    'etaT': PropertyDetailDefaultValue;
    'unitAngle': PropertyDetailDefaultValue;
    'unitLength': PropertyDetailDefaultValue;
    'unitArea': PropertyDetailDefaultValue;
    'unitStress': PropertyDetailDefaultValue;
    'unitForce': PropertyDetailDefaultValue;
    'unitMoment': PropertyDetailDefaultValue;
    'unitTemperature': PropertyDetailDefaultValue;
    'unitForcePerLength': PropertyDetailDefaultValue;
    'unitDensity': PropertyDetailDefaultValue;
    'unitAreaPerLength': PropertyDetailDefaultValue;
}, AppPropertyId>;

/**
 * Define a minimum required filter for each property to sucesfully get the property detail.
 */
export type AppPropertyFilterMap = Pick<{
    'designStandardId': PropertyFilterDesignTypeRegion;
    'e': PropertyFilterDesignStandard;
    'kc': undefined;
    'gammaS': PropertyFilterRegion;
    'gammaC': PropertyFilterRegion;
    'alphaCC': PropertyFilterRegion;
    'etaT': undefined;
    'unitAngle': PropertyFilterRegion;
    'unitLength': PropertyFilterRegion;
    'unitArea': PropertyFilterRegion;
    'unitStress': PropertyFilterRegion;
    'unitForce': PropertyFilterRegion;
    'unitMoment': PropertyFilterRegion;
    'unitTemperature': PropertyFilterRegion;
    'unitForcePerLength': PropertyFilterRegion;
    'unitDensity': PropertyFilterRegion;
    'unitAreaPerLength': PropertyFilterRegion;
}, AppPropertyId>;

type AppPropertyFilterMapOptional = Record<keyof Pick<AppPropertyFilterMap, PickKeys<AppPropertyFilterMap, undefined>>, undefined | PropertyFilter>;
type AppPropertyFilterMapRequired = Omit<AppPropertyFilterMap, PickKeys<AppPropertyFilterMap, undefined>>;

export type ApiAppPropertyId = keyof Pick<AppPropertyDetailMap,
    'designStandardId' |
    'e' |
    'kc' |
    'gammaS' |
    'gammaC' |
    'alphaCC' |
    'etaT'
>;
export type ApiAppPropertyDetails = Record<ApiAppPropertyId, ApiPropertyDetailGroup[]>;

type PropertyFilterRegion = Pick<PropertyFilter, 'regionId'>;
type PropertyFilterDesignTypeRegion = Pick<PropertyFilter, 'designTypeId' | 'regionId'>;
type PropertyFilterDesignStandard = Pick<PropertyFilter, 'designStandardId'>;

type PropertyFilter = Required<PropertyDetailFilter>;

interface PropertyDetailFilter {
    designTypeId?: DesignTypeId;
    regionId?: number;
    designStandardId?: number;
    concreteMemberId?: number;
}

interface ApiPropertyDetailGroup extends PropertyDetailFilter {
    propertyDetail: PropertyDetail;
}

export interface PropertyDetail {
    defaultValue?: number;
    minValue?: number;
    maxValue?: number;
    minLength?: number;
    maxLength?: number;
    precision?: number;
    extraPrecision?: number;
    allowedValues?: number[];
    disabled?: boolean;
    hidden?: boolean;
}

export interface AppDataRegion {
    id: number;
}

export interface Region {
    id: number;
    nameKey: string;
    strength: boolean;
    punch: boolean;
}

export interface DesignStandard {
    id: number;
    nameKey: string;
    descriptionKey: string;
}

export interface PostInstalledReinforcementDesign {
    id: number;
    nameKey: string;
}

export interface Beta {
    id: number;
    nameKey: string;
}

export interface LoadType {
    id: number;
    tooltipTitleKey?: string;
    tooltipKey?: string;
    image: string;
    name?: string;
}

export interface BaseMaterial {
    id: number;
    name?: string;
    nameKey?: string;
}

export interface AggregateSize {
    id: number;
    name: string;
    size: number;
}

export interface ZonesNumber {
    id: number;
    name: string;
    number: ZoneNumber;
}

export interface OpeningsNumber {
    id: number;
    name: string;
    number: ZoneNumber;
}

export type ZoneNumber = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export type ZoneNumberWithoutOpening = 1 | 2 | 3 | 4;

export interface DrillingType {
    id: number;
    nameKey: string;
}

export interface HoleType {
    id: number;
    nameKey: string;
}

export interface DrillingAid {
    id: number;
    nameKey: string;
}

export interface InstallationDirection {
    id: number;
    nameKey: string;
}

export interface ReinforcementArrangement {
    id: number;
    nameKey: string;
}

export interface ConcreteMember {
    id: number;
    nameKey: string;
    image: string;
    sortOrder: number;
    hidden: boolean;
}
export type CompressionMember = ConcreteMember;

export type BaseMember = ConcreteMember;

export interface Unit {
    id: number;
    name: string;
}

export interface FastenerFamilyGroup {
    id: number;
    name: string;
    image: string;
    regionalNames?: Record<number, string>;
    regionalSortNumbers?: Record<number, number>;
}

export interface FastenerFamily {
    id: number;
    name: string;
    regionalNames?: Record<number, string>;
    regionalSortNumbers?: Record<number, number>;
}

export interface Fastener {
    id: number;
    name: string;
    size: number;
    holeDia: number;
}

export interface ColumnPosition {
    id: number;
    nameKey: string;
}

type PropertyDetailGroups = Record<AppPropertyId, ApiPropertyDetailGroup[]>;

@Injectable({
    providedIn: 'root'
})
export class DataService {
    public regions!: Region[];
    public regionsById!: Record<number, Region>;
    public strengthRegions!: Region[];
    public strengthRegionsById!: Record<number, Region>;
    public punchRegions!: Region[];
    public punchRegionsById!: Record<number, Region>;
    public strengthNewLabelRegions!: Region[];
    public strengthNewLabelRegionsById!: Record<number, Region>;
    public punchNewLabelRegions!: Region[];
    public punchNewLabelRegionsById!: Record<number, Region>;
    public peRegions!: Region[];
    public peRegionsById!: Record<number, Region>;

    public designStandards!: DesignStandard[];
    public designStandardsById!: Record<number, DesignStandard>;

    public zonesNumbers!: ZonesNumber[];
    public zonesNumbersById!: Record<number, ZonesNumber>;

    public openingsNumbers!: OpeningsNumber[];
    public openingsNumbersById!: Record<number, OpeningsNumber>;
    public fasteners!: Fastener[];
    public fastenersById!: Record<number, Fastener>;

    public fastenerFamilies!: FastenerFamily[];
    public fastenerFamiliesById!: Record<number, FastenerFamily>;

    public fastenerFamilyGroups!: FastenerFamilyGroup[];
    public fastenerFamilyGroupsById!: Record<number, FastenerFamilyGroup>;

    public baseMaterials!: BaseMaterial[];
    public baseMaterialsById!: Record<number, BaseMaterial>;

    public postInstalledReinforcementDesigns!: PostInstalledReinforcementDesign[];
    public postInstalledReinforcementDesignsById!: Record<number, PostInstalledReinforcementDesign>;

    public betaValues!: Beta[];
    public betaValuesById!: Record<number, Beta>;

    public aggregateSizes!: AggregateSize[];
    public aggregateSizesById!: Record<number, AggregateSize>;

    public reinforcementArrangements!: ReinforcementArrangement[];
    public reinforcementArrangementsById!: Record<number, ReinforcementArrangement>;

    public etaTValues!: number[];
    public loadTypes!: LoadType[];
    public loadTypesById!: Record<number, LoadType>;

    public drillingTypes!: DrillingType[];
    public drillingTypesById!: Record<number, DrillingType>;

    public holeTypes!: HoleType[];
    public holeTypesById!: Record<number, HoleType>;

    public drillingAids!: DrillingAid[];
    public drillingAidsById!: Record<number, DrillingAid>;

    public installationDirections!: InstallationDirection[];
    public installationDirectionsById!: Record<number, InstallationDirection>;

    public concreteMembers!: ConcreteMember[];
    public concreteMembersById!: Record<number, ConcreteMember>;

    public compressionMembers!: CompressionMember[];
    public compressionMembersById!: Record<number, CompressionMember>;

    public columnPositions!: ColumnPosition[];
    public columnPositionsById!: Record<number, ColumnPosition>;

    public baseMembers!: BaseMember[];
    public baseMembersById!: Record<number, BaseMember>;

    public reportTypes!: CodeList[];
    public reportTypesById!: Record<number, CodeList>;

    public languages!: CodeList[];
    public languagesById!: Record<number, CodeList>;

    public paperSizes!: CodeList[];
    public paperSizesById!: Record<number, CodeList>;

    public units!: {
        length: Unit[];
        lengthById: Record<number, Unit>;
        area: Unit[];
        areaById: Record<number, Unit>;
        stress: Unit[];
        stressById: Record<number, Unit>;
        force: Unit[];
        forceById: Record<number, Unit>;
        moment: Unit[];
        momentById: Record<number, Unit>;
        temperature: Unit[];
        temperatureById: Record<number, Unit>;
        forcePerLength: Unit[];
        forcePerLengthById: Record<number, Unit>;
        density: Unit[];
        densityById: Record<number, Unit>;
        areaPerLength: Unit[];
        areaPerLengthById: Record<number, Unit>;
    };

    /** for public access use properties like dataService.fasteners, dataService.fastenersById... */
    private appData!: AppData;
    private propertyDetails!: PropertyDetailGroups;

    constructor(
        private commonCodeListService: CommonCodeListService,
        private spApiService: SpApiService,
        private localizationService: LocalizationService,
        private featureVisibilityService: FeatureVisibilityService,
    ) { }

    public async loadData() {
        this.appData = await this.spApiService.api.app.data();

        this.initRegions();
        this.initUnits();

        this.initDesignStandards();
        this.initZonesNumbers();
        this.initFasteners();
        this.initFastenerFamilies();
        this.initFastenerFamilyGroups();
        this.initBaseMaterials();
        this.initPostInstalledReinforcementDesigns();
        this.initBetaValues();
        this.initLoadTypes();
        this.initDrillingTypes();
        this.initHoleTypes();
        this.initDrillingAids();
        this.initInstallationDirections();
        this.initConcreteMembers();
        this.initCompressionMembers();
        this.initBaseMembers();
        this.initAggregateSize();
        this.initReportTypes();
        this.initLanguages();
        this.initPaperSizes();
        this.InitEtaTValues();
        this.initReinforcementArrangement();
        this.initOpeningsNumbers();
        this.initColumnPositions();

        this.initProperties();
    }

    /**
     * Get property detail for a property with optional filters.
     * For properties in AppPropertyFilterMapOptional.
     * @param propertyId key of AppPropertyFilterMapOptional
     * @returns Property detail
     * @example dataService.getPropertyDetail('e');
     */
    public getPropertyDetail<K extends keyof AppPropertyFilterMapOptional>(propertyId: K): AppPropertyDetailMap[K];
    /**
     * Get property detail for a property with required filters.
     * For properties in AppPropertyFilterMapRequired.
     * @param propertyId key of AppPropertyFilterMapRequired
     * @param filter filter object defined in AppPropertyFilterMapRequired[propertyId]
     * @returns Property detail
     * @example dataService.getPropertyDetail('alphaCC', { regionId: 0 });
     */
    public getPropertyDetail<K extends keyof AppPropertyFilterMapRequired>(propertyId: K, filter: AppPropertyFilterMap[K]): AppPropertyDetailMap[K];
    /**
     * It's IMPORTANT to use the other overloads instead when possible.
     * This overload is not recommended for use and can be used only when we can't know whether the property is part of AppPropertyFilterMapOptional or AppPropertyFilterMapRequired.
     * @param propertyId AppPropertyId
     * @param filter PropertyFilter
     * @example dataService.getPropertyDetail(appPropertyId, { regionId: 0, designStandardId: 0, concreteMemberId: 0 });
     */
    public getPropertyDetail<K extends keyof AppPropertyFilterMap>(propertyId: K, filter: PropertyFilter): AppPropertyDetailMap[K];
    public getPropertyDetail<K extends keyof AppPropertyFilterMap>(propertyId: K, filter?: AppPropertyFilterMap[K]): AppPropertyDetailMap[K] {
        // check minimum required filters to successfully get the property detail
        const propertyDetailGroupFiltersRequired: string[] = [];

        // TODO: instead of supporting only ApiAppPropertyIds, support all AppPropertyIds instead (like other sand modules)
        if (!this.propertyDetails[propertyId]) {
            return null!;
        }

        for (const propertyDetailGroup of this.propertyDetails[propertyId]) {
            const propertyDetailGroupFilterNames = this.getPropertyDetailGroupDefinedFilterKeys(propertyDetailGroup);
            propertyDetailGroupFiltersRequired.push(...propertyDetailGroupFilterNames.filter(x => !propertyDetailGroupFiltersRequired.includes(x)));
        }

        // if all required filters are not provided, throw an error
        // explanation:
        //  if the property has multiple data groups with multiple defined filters,
        //  we need to know which group to use, so we need to provide those required filters when fetching data
        const providedFilterNames = filter ? this.getDefinedFilterKeys(filter) : [];
        if (propertyDetailGroupFiltersRequired.some(x => !providedFilterNames.includes(x))) {
            throw new Error(`Data for PropertyDetail for ${propertyId} requires filters: ${propertyDetailGroupFiltersRequired.join(',')}, but was queried by providing filters: ${providedFilterNames.join(',') ?? 'none'}`);
        }

        // if filter is not provided, get the property detail of the first (and expected to be single) group
        if (filter == null) {
            if (this.propertyDetails[propertyId].length > 1) {
                // if this check fails, something is wrong with validation above
                throw new Error(`Multiple PropertyDetailGroups found for ${propertyId}, but no filters provided to decide which one to use`);
            }

            return this.propertyDetails[propertyId][0].propertyDetail as unknown as AppPropertyDetailMap[K];
        }

        return this.getPropertyDetailInternal(propertyId, filter);
    }

    private getPropertyDetailInternal<K extends keyof AppPropertyDetailMap>(propertyId: K, filter: Partial<PropertyFilter>): AppPropertyDetailMap[K] {
        let propertyDetailGroups = this.propertyDetails[propertyId];

        for (const filterProperty in filter) {
            propertyDetailGroups = propertyDetailGroups
                .filter(
                    group =>
                        group[filterProperty as keyof ApiPropertyDetailGroup] == filter[filterProperty as keyof PropertyFilter] ||
                        group[filterProperty as keyof ApiPropertyDetailGroup] == null
                );
        }

        // if no propertyDetailGroup is found, there is an issue with data
        if (propertyDetailGroups.length < 1) {
            const providedFilterNames = this.getDefinedFilterKeys(filter);
            throw new Error(`No matching data for ${propertyId} found with filters for: ${providedFilterNames.join(',')}`);
        }

        if (propertyDetailGroups.length > 1) {
            // sort by defined filter values count (more values = more specific filter), so we know which group to favor
            const sortedPropertyDetailGroups = propertyDetailGroups.sort((groupA, groupB) => {
                const aFilterValuesCount = this.getPropertyDetailGroupDefinedFilterKeys(groupA).length;
                const bFilterValuesCount = this.getPropertyDetailGroupDefinedFilterKeys(groupB).length;
                return bFilterValuesCount - aFilterValuesCount;
            });

            const firstFilterLevel = this.getPropertyDetailGroupDefinedFilterKeys(sortedPropertyDetailGroups[0]).length;
            const secondFilterLevel = this.getPropertyDetailGroupDefinedFilterKeys(sortedPropertyDetailGroups[1]).length;

            if (firstFilterLevel == secondFilterLevel) {
                // if there are several groups with the same filter values count,
                // we don't know how to decide which one to use, so we throw an error
                throw new Error(`Multiple PropertyDetailGroups found with the same filter values count`);
            }

            return sortedPropertyDetailGroups[0].propertyDetail as unknown as AppPropertyDetailMap[K];
        }

        return propertyDetailGroups[0].propertyDetail as unknown as AppPropertyDetailMap[K];
    }

    private getDefinedFilterKeys(filter: PropertyDetailFilter): string[] {
        return Object.keys(filter).filter(x => filter[x as keyof PropertyDetailFilter] != null);
    }

    private getPropertyDetailGroupDefinedFilterKeys(propertyDetailGroup: ApiPropertyDetailGroup): string[] {
        return Object.keys(propertyDetailGroup).filter(x => x != 'propertyDetail' && propertyDetailGroup[x as keyof ApiPropertyDetailGroup] != null);
    }

    public getItemName(item: { name?: string; nameKey?: string }): string {
        if (item.nameKey) {
            return this.localizationService.getString(item.nameKey);
        }

        return item.name ?? '';
    }

    private initProperties() {
        const commonRegions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];
        const moduleSupportedRegions = commonRegions.filter(x => x.id in this.regionsById);

        this.propertyDetails = {
            designStandardId: this.appData.propertyDetails.designStandardId,
            e: this.appData.propertyDetails.e,
            kc: this.appData.propertyDetails.kc,
            gammaS: this.appData.propertyDetails.gammaS,
            gammaC: this.appData.propertyDetails.gammaC,
            alphaCC: this.appData.propertyDetails.alphaCC,
            etaT: this.appData.propertyDetails.etaT,
            unitLength: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitLength'),
            unitArea: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitArea'),
            unitStress: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitStress'),
            unitForce: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitForce'),
            unitMoment: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitMoment'),
            unitTemperature: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitTemperature'),
            unitForcePerLength: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitForcePerLength'),
            unitDensity: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitDensity'),
            unitAreaPerLength: getUnitPropertyGroups(moduleSupportedRegions, 'defaultUnitAreaPerLength'),
            // angle does not have a default unit in pe-ui so we take degrees for all regions
            unitAngle: [{ propertyDetail: { defaultValue: UnitType.degree } } as ApiPropertyDetailGroup]
        };

        // add units as properties from common code list
        function getUnitPropertyGroups<T extends PickKeys<CommonRegion, number | null | undefined>>(regions: CommonRegion[], defaultProperty: T) {
            return regions.map(commonRegion => ({
                regionId: commonRegion.id,
                propertyDetail: {
                    defaultValue: (commonRegion[defaultProperty as keyof CommonRegion] as number | null | undefined) ?? undefined
                }
            }));
        }
    }

    private toDictionary<T, P extends keyof T>(values: T[], property: P) {
        return values.reduce<Record<string, unknown>>((valuesByProperty, value) => {
            valuesByProperty[value[property] as string] = value;
            return valuesByProperty;
        }, {}) as Record<T[P] extends string | number | symbol ? T[P] : never, T>;
    }

    private groupById<T, P extends PickKeys<T, number[]>>(values: T[], property: P, ids: number[]) {
        const valuesById: Record<number, T[]> = {};

        for (const id of ids) {
            valuesById[id] = [];
        }

        for (const value of values) {
            for (const id of value[property] as number[]) {
                if (id in valuesById) {
                    valuesById[id].push(value);
                }
            }
        }

        return valuesById;
    }

    private initRegions(): void {
        const commonRegions = this.commonCodeListService.commonCodeLists[CommonCodeList.Region] as CommonRegion[];

        const strengthRegionsData = this.featureVisibilityService.getFeatureValue<number[]>('SP_StrengthRegions', []);
        const punchRegionsData = this.featureVisibilityService.getFeatureValue<number[]>('SP_PunchRegions', []);

        const strengthRegionIds = strengthRegionsData
            ? Object.fromEntries(strengthRegionsData.map(x => [x, undefined]))
            : Object.fromEntries(this.appData.regions.map(x => [x.id, undefined]));

        const punchRegionIds = punchRegionsData
            ? Object.fromEntries(punchRegionsData.map(x => [x, undefined]))
            : Object.fromEntries(this.appData.regions.map(x => [x.id, undefined]));

        const strengthAllowedRegions = Object.fromEntries(this.appData.regions.filter(x => x.id in strengthRegionIds).map(x => [x.id, undefined]));
        const punchAllowedRegions = Object.fromEntries(this.appData.regions.filter(x => x.id in punchRegionIds).map(x => [x.id, undefined]));

        // all regions
        this.peRegions = commonRegions
            .map((x): Region => ({
                id: x.id,
                // take the translation key from pe-ui
                nameKey: x.nameResourceKey!,
                strength: x.id in strengthAllowedRegions,
                punch: x.id in punchAllowedRegions
            }));
        this.peRegionsById = this.toDictionary(this.peRegions, 'id');

        // S&P regions
        this.regions = this.peRegions.filter(x => x.strength || x.punch);
        this.regionsById = this.toDictionary(this.regions, 'id');

        this.strengthRegions = this.peRegions.filter(x => x.strength);
        this.strengthRegionsById = this.toDictionary(this.strengthRegions, 'id');

        this.punchRegions = this.peRegions.filter(x => x.punch);
        this.punchRegionsById = this.toDictionary(this.punchRegions, 'id');

        // New Label Regions
        this.initNewLabelRegions();
    }

    private initNewLabelRegions(): void {
        const strengthNewLabelRegionsData = this.featureVisibilityService.getFeatureValue<number[]>('SP_ContentFeature_StrengthNewLabel', []);
        const punchNewLabelRegionsData = this.featureVisibilityService.getFeatureValue<number[]>('SP_ContentFeature_PunchNewLabel', []);

        const strengthNewLabelRegionIds = strengthNewLabelRegionsData
            ? Object.fromEntries(strengthNewLabelRegionsData.map(x => [x, undefined]))
            : Object.fromEntries(this.appData.regions.map(x => [x.id, undefined]));

        const punchNewLabelRegionIds = punchNewLabelRegionsData
            ? Object.fromEntries(punchNewLabelRegionsData.map(x => [x, undefined]))
            : Object.fromEntries(this.appData.regions.map(x => [x.id, undefined]));

        const strengthShowNewLabelRegions = Object.fromEntries(
            this.appData.regions.filter(x => x.id in strengthNewLabelRegionIds).map(x => [x.id, undefined])
        );
        const punchShowNewLabelRegions = Object.fromEntries(
            this.appData.regions.filter(x => x.id in punchNewLabelRegionIds).map(x => [x.id, undefined])
        );

        this.strengthNewLabelRegions = this.peRegions.filter(x => x.id in strengthShowNewLabelRegions);
        this.strengthNewLabelRegionsById = this.toDictionary(this.strengthNewLabelRegions, 'id');

        this.punchNewLabelRegions = this.peRegions.filter(x => x.id in punchShowNewLabelRegions);
        this.punchNewLabelRegionsById = this.toDictionary(this.punchNewLabelRegions, 'id');
    }

    private initUnits(): void {
        this.units = {
            length: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitLength] as Unit[],
            lengthById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitLength] as Unit[], 'id'),

            area: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitArea] as Unit[],
            areaById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitArea] as Unit[], 'id'),

            stress: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitStress] as Unit[],
            stressById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitStress] as Unit[], 'id'),

            force: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitForce] as Unit[],
            forceById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitForce] as Unit[], 'id'),

            moment: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitMoment] as Unit[],
            momentById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitMoment] as Unit[], 'id'),

            temperature: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitTemperature] as Unit[],
            temperatureById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitTemperature] as Unit[], 'id'),

            forcePerLength: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitForcePerLength] as Unit[],
            forcePerLengthById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitForcePerLength] as Unit[], 'id'),

            density: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitDensity] as Unit[],
            densityById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitDensity] as Unit[], 'id'),

            areaPerLength: this.commonCodeListService.commonCodeLists[CommonCodeList.UnitAreaPerLength] as Unit[],
            areaPerLengthById: this.toDictionary(this.commonCodeListService.commonCodeLists[CommonCodeList.UnitAreaPerLength] as Unit[], 'id')
        };
    }

    private initDesignStandards() {
        this.designStandards = this.appData.designStandards;
        this.designStandardsById = this.toDictionary(this.designStandards, 'id');
    }

    private initZonesNumbers() {
        this.zonesNumbers = this.appData.zonesNumbers;
        this.zonesNumbersById = this.toDictionary(this.zonesNumbers, 'id');
    }

    private initFasteners() {
        this.fasteners = this.appData.fasteners;
        this.fastenersById = this.toDictionary(this.fasteners, 'id');
    }

    private initFastenerFamilies() {
        this.fastenerFamilies = this.appData.fastenerFamilies;
        this.fastenerFamiliesById = this.toDictionary(this.fastenerFamilies, 'id');
    }

    private initFastenerFamilyGroups() {
        this.fastenerFamilyGroups = this.appData.fastenerFamilyGroups;
        this.fastenerFamilyGroupsById = this.toDictionary(this.fastenerFamilyGroups, 'id');
    }

    private initBaseMaterials() {
        this.baseMaterials = this.appData.baseMaterials;
        this.baseMaterialsById = this.toDictionary(this.baseMaterials, 'id');
    }

    private initPostInstalledReinforcementDesigns() {
        this.postInstalledReinforcementDesigns = this.appData.postInstalledReinforcementDesigns;
        this.postInstalledReinforcementDesignsById = this.toDictionary(this.postInstalledReinforcementDesigns, 'id');
    }

    private initBetaValues() {
        this.betaValues = this.appData.betaValues;
        this.betaValuesById = this.toDictionary(this.betaValues, 'id');
    }

    private initLoadTypes() {
        this.loadTypes = this.appData.loadTypes;
        this.loadTypesById = this.toDictionary(this.loadTypes, 'id');
    }

    private initDrillingTypes() {
        this.drillingTypes = this.appData.drillingTypes;
        this.drillingTypesById = this.toDictionary(this.drillingTypes, 'id');
    }

    private initHoleTypes() {
        this.holeTypes = this.appData.holeTypes;
        this.holeTypesById = this.toDictionary(this.holeTypes, 'id');
    }

    private initDrillingAids() {
        this.drillingAids = this.appData.drillingAids;
        this.drillingAidsById = this.toDictionary(this.drillingAids, 'id');
    }

    private initInstallationDirections() {
        this.installationDirections = this.appData.installationDirections;
        this.installationDirectionsById = this.toDictionary(this.installationDirections, 'id');
    }

    private initConcreteMembers() {
        this.concreteMembers = this.appData.concreteMembers;
        this.concreteMembersById = this.toDictionary(this.concreteMembers, 'id');
    }
    private initCompressionMembers() {
        this.compressionMembers = this.appData.compressionMembers;
        this.compressionMembersById = this.toDictionary(this.compressionMembers, 'id');
    }
    private initBaseMembers() {
        this.baseMembers = this.appData.baseMembers;
        this.baseMembersById = this.toDictionary(this.baseMembers, 'id');
    }

    private initAggregateSize() {
        this.aggregateSizes = this.appData.aggregateSizes;
        this.aggregateSizesById = this.toDictionary(this.aggregateSizes, 'id');
    }

    private initReinforcementArrangement() {
        this.reinforcementArrangements = this.appData.reinforcementArrangements;
        this.reinforcementArrangementsById = this.toDictionary(this.reinforcementArrangements, 'id');
    }

    private initReportTypes() {
        this.reportTypes = this.commonCodeListService.commonCodeLists[CommonCodeList.ReportType];
        this.reportTypesById = this.toDictionary(this.reportTypes, 'id');
    }

    private initLanguages() {
        this.languages = this.commonCodeListService.commonCodeLists[CommonCodeList.Language];
        this.languagesById = this.toDictionary(this.languages, 'id');
    }

    private initPaperSizes() {
        this.paperSizes = this.commonCodeListService.commonCodeLists[CommonCodeList.ReportPaperSize];
        this.paperSizesById = this.toDictionary(this.paperSizes, 'id');
    }

    private InitEtaTValues() {
        this.etaTValues = this.appData.etaTValues;
    }

    private initOpeningsNumbers() {
        this.openingsNumbers = this.appData.openingsNumbers;
        this.openingsNumbersById = this.toDictionary(this.openingsNumbers, 'id');
    }

    private initColumnPositions() {
        this.columnPositions = this.appData.columnPositions;
        this.columnPositionsById = this.toDictionary(this.columnPositions, 'id');
    }
}
