export class Change {
  name;
  oldValue;
  newValue;
  constructor(change) {
    this.name = change?.name ?? '';
    this.oldValue = change?.oldValue;
    this.newValue = change?.newValue;
  }
}
export class ChangesServiceBase {}
export class ChangesServiceInjected extends ChangesServiceBase {
  setBaseService(baseService) {
    this.getDeepChanges = baseService.getDeepChanges.bind(baseService);
    this.getShallowChanges = baseService.getShallowChanges.bind(baseService);
    this.chainChanges = baseService.chainChanges.bind(baseService);
  }
  // ChangesServiceBase methods
  /* eslint-disable @typescript-eslint/no-unused-vars */
  getDeepChanges(_source, _target, _ignoreUndefined) {
    return {};
  }
  getShallowChanges(_source, _target, _ignoreUndefined) {
    return {};
  }
  chainChanges(..._changes) {
    return [];
  }
}
