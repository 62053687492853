import { NavigationTabWidth } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { getSpriteAsIconStyle } from '../../sprites';
import { PunchRegion, PunchTab } from './static-menu.punch.definitions';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

export function createSPMenu_ExistingReinforcement(): PunchTab {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement',
        Id: 3,
        Name: 'existingreinforcement-tab',
        Image: 'tab-existing-reinforcement',
        IconImage: getSpriteAsIconStyle('sprite-tab-existing-reinforcement'),
        Width: NavigationTabWidth.Normal,
        TabRegions: [
            createSPMenu_ExistingReinforcement_Materials(),
            createSPMenu_ExistingReinforcement_TensileReinforcement(),
        ]
    };
}

function createSPMenu_ExistingReinforcement_Materials(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionMaterials',
        Id: 30,
        Name: 'materials-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'materials-reinforcement-yield-strength',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionMaterials.ReiforcementYieldStrength',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'reinforcementYieldStrength'
            },
            {
                ControlType: 'TextBox',
                Name: 'materials-reinforcement-yield-strength-sia',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionMaterials.ReiforcementYieldStrength.Sia',
                UnitGroup: UnitGroup.Stress,
                UIPropertyId: 'reinforcementYieldStrengthSia'
            }
        ]
    };
}

function createSPMenu_ExistingReinforcement_TensileReinforcement(): PunchRegion {
    return {
        DisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement',
        Id: 30,
        Name: 'tensile-reinforcement-section',
        Controls: [
            {
                ControlType: 'TextBox',
                Name: 'cross-sectional-area-per-section-x',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalAreaPerSectionX',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'crossSectionalAreaX'
            },
            {
                ControlType: 'TextBox',
                Name: 'cover-to-center-x',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CoverToCenterX',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'coverX'
            },
            {
                ControlType: 'TextBox',
                Name: 'effective-height-x',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeightX',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'effectiveHeightX'
            },
            {
                ControlType: 'TextBox',
                Name: 'cross-sectional-area-per-section-y',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CrossSectionalAreaPerSectionY',
                UnitGroup: UnitGroup.Area,
                UIPropertyId: 'crossSectionalAreaY'
            },
            {
                ControlType: 'TextBox',
                Name: 'cover-to-center-y',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.CoverToCenterY',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'coverY'
            },
            {
                ControlType: 'TextBox',
                Name: 'effective-height-y',
                TitleDisplayKey: 'SP.Navigation.TabExistingReinforcement.RegionTensileReinforcement.EffectiveHeightY',
                UnitGroup: UnitGroup.Length,
                UIPropertyId: 'effectiveHeightY'
            }
        ]
    };
}
