import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3, Vector2, Vector3 } from '@babylonjs/core/Maths/math';
import { CreateCylinder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { CreatePolygon } from '@babylonjs/core/Meshes/Builders/polygonBuilder';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import earcut from 'earcut';
import isEqual from 'lodash-es/isEqual';
import { PunchComponent, PunchComponentConstructor } from '../../punch-component';
import { PunchBaseMaterialModel, PunchPostInstalledElementModel, VisibilityModel } from '../../punch-gl-model';
import { baseMaterialAlphaIndex, getPunchSpanOffset, getPunchWasherDiameter, getRecessHoleZPosition, getWasherZPosition, translateKernelPointTo3dPoint } from './punch-helper';
import { Text2D } from '../../gl-model';
import { CreatePlane } from '@babylonjs/core/Meshes/Builders/planeBuilder';

interface PunchBaseMaterialModelUpdateData {
    visibilityInfo: VisibilityModel;
    baseMaterial: PunchBaseMaterialModel;
    postInstalledElement: PunchPostInstalledElementModel;
}

interface OpeningInfo {
    length: number;
    width: number;
    originX: number;
    originY: number;
}

const enum SidePosition {
    Left,
    Top,
    Right,
    Bottom
}

interface SideInfo {
    sidePosition: SidePosition;
    intervals: Interval[];
}

interface Interval {
    origin: number;
    length: number;
    position: number;
}

export const baseMaterialColor = Color3.FromHexString('#cbcabf');
export const baseMaterialDarkColor = Color3.FromHexString('#a9a89a');
export const numberOfSides = 16;
export const OpeningNumberHeightOffset = 100;

export class PunchBaseMaterialElement extends PunchComponent {
    private baseMaterialMaterial!: StandardMaterial;
    private baseMaterialMaterialDark!: StandardMaterial;
    private materialMeshTop: Mesh | null = null;
    private materialMeshBottom: Mesh | null = null;
    private sideMeshes: Mesh[];

    private baseMaterialModelUpdateData!: PunchBaseMaterialModelUpdateData;

    private recessCylinders: Mesh[];
    private recessHoleBottoms: Mesh[];

    private openingNumberingTextTop: Text2D[];
    private openingNumberingTextBottom: Text2D[];

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
        this.baseMaterialModelUpdateData = {} as PunchBaseMaterialModelUpdateData;
        this.recessCylinders = [];
        this.recessHoleBottoms = [];
        this.openingNumberingTextTop = [];
        this.openingNumberingTextBottom = [];
        this.sideMeshes = new Array<Mesh>(4);

        this.initMaterial();
    }

    public update(): void {
        const modelUpdateData = {
            visibilityInfo: this.getVisibilityInfos(),
            baseMaterial: this.model.baseMaterial,
            postInstalledElement: this.model.punchPostInstalledElement
        } as PunchBaseMaterialModelUpdateData;

        if (isEqual(this.baseMaterialModelUpdateData, modelUpdateData)) {
            return;
        }
        this.baseMaterialModelUpdateData = structuredClone(modelUpdateData);

        this.ensureBaseMaterialSides();
        this.ensureBaseMaterialTop();
        this.ensureBaseMaterialBottom();

        this.openingNumberingTextTop.forEach(x => x?.setEnabled(false));
        this.openingNumberingTextBottom.forEach(x => x?.setEnabled(false));
        if (this.model.visibilityModel.OpeningNumbering) {
            this.ensureOpeningNumbering();
        }
    }

    private getVisibilityInfos(): VisibilityModel {
        return {
            TransparentConcrete: this.model.visibilityModel.TransparentConcrete,
            ConcreteDimensionsVisible: this.model.visibilityModel.ConcreteDimensionsVisible,
            CriticalPerimeter: this.model.visibilityModel.CriticalPerimeter,
            StrengtheningElementSpacingDimensions: this.model.visibilityModel.StrengtheningElementSpacingDimensions,
            StrengtheningElementEdgeDistanceDimensions: this.model.visibilityModel.StrengtheningElementEdgeDistanceDimensions,
            OpeningNumbering: this.model.visibilityModel.OpeningNumbering,
            OpeningDimensions: this.model.visibilityModel.OpeningDimensions
        };
    }

    private initMaterial(): void {
        this.baseMaterialMaterial = this.createMaterial('BaseMaterialMaterial', baseMaterialColor);
        this.baseMaterialMaterialDark = this.createMaterial('BaseMaterialMaterialDark', baseMaterialDarkColor);
    }

    private ensureBaseMaterialSides() {
        this.sideMeshes.forEach(x => x?.setEnabled(false));
        const planes = this.getPlanes();
        for (const [i, side] of planes.entries()) {
            for (let j = 0; j < side.intervals.length; j++) {
                const mesh = this.createSideMesh(side, j);
                mesh.setEnabled(true);
                this.sideMeshes[i] = mesh;
            }
        }
    }

    private getPlanes() {
        const numberOfSides = 4;
        const sideInfos: SideInfo[] = [];

        const baseMemberLeftmostPoint = -this.model.baseMaterial.spanNegX;
        const baseMemberRightmostPoint = this.model.baseMaterial.spanPosX;
        const baseMemberTopmostPoint = this.model.baseMaterial.spanPosY;
        const baseMemberBottommostPoint = -this.model.baseMaterial.spanNegY;

        const totalBaseMemberWidth = baseMemberTopmostPoint - baseMemberBottommostPoint;
        const totalBaseMemberLength = baseMemberRightmostPoint - baseMemberLeftmostPoint;

        const baseMaterialCenterX = (baseMemberLeftmostPoint + baseMemberRightmostPoint) / 2;
        const baseMaterialCenterY = (baseMemberTopmostPoint + baseMemberBottommostPoint) / 2;

        const openings = this.getOpenings();
        for (let i = 0; i < numberOfSides; i++) {
            const sidePosition: SidePosition = i as SidePosition;
            const sideInfo: SideInfo = {
                sidePosition: sidePosition,
                intervals: [],
            };

            openings.forEach((opening) => {
                const openingLeftmostPoint = opening.originX - opening.length / 2;
                const openingRightmostPoint = opening.originX + opening.length / 2;
                const openingTopmostPoint = opening.originY + opening.width / 2;
                const openingBottommostPoint = opening.originY - opening.width / 2;

                switch (sidePosition) {
                    case SidePosition.Left:
                        if (openingLeftmostPoint <= baseMemberLeftmostPoint) {
                            sideInfo.intervals.push(this.createInterval(opening.originY, opening.width, baseMemberLeftmostPoint));
                        }
                        break;
                    case SidePosition.Top:
                        if (openingTopmostPoint >= baseMemberTopmostPoint) {
                            sideInfo.intervals.push(this.createInterval(opening.originX, opening.length, baseMemberTopmostPoint));
                        }
                        break;
                    case SidePosition.Right:
                        if (openingRightmostPoint >= baseMemberRightmostPoint) {
                            sideInfo.intervals.push(this.createInterval(opening.originY, opening.width, baseMemberRightmostPoint));
                        }
                        break;
                    case SidePosition.Bottom:
                        if (openingBottommostPoint <= baseMemberBottommostPoint) {
                            sideInfo.intervals.push(this.createInterval(opening.originX, opening.length, baseMemberBottommostPoint));
                        }
                        break;
                }
            });
            const position = this.getPosition(sidePosition);
            if (sideInfo.intervals.length === 0) {
                let length;
                let origin;
                if (sidePosition == SidePosition.Left || sidePosition == SidePosition.Right) {
                    length = totalBaseMemberWidth;
                    origin = baseMaterialCenterY;
                }
                else {
                    length = totalBaseMemberLength;
                    origin = baseMaterialCenterX;
                }
                sideInfo.intervals.push(this.createInterval(origin, length, position));
            }
            else {
                const subtractedIntervals = this.subtractIntervals(this.getBaseInterval(sidePosition), sideInfo.intervals);
                sideInfo.intervals = [];
                subtractedIntervals.forEach((subtractedInterval) => {
                    const origin = (subtractedInterval[0] + subtractedInterval[1]) / 2;
                    const length = subtractedInterval[1] - subtractedInterval[0];
                    sideInfo.intervals.push(this.createInterval(origin, length, this.getPosition(sidePosition)));
                });
            }
            sideInfos.push(sideInfo);
        };
        return sideInfos;
    }

    private getPosition(sidePosition: SidePosition) {
        switch (sidePosition) {
            case SidePosition.Left:
                return -this.model.baseMaterial.spanNegX;
            case SidePosition.Top:
                return this.model.baseMaterial.spanPosY;
            case SidePosition.Right:
                return this.model.baseMaterial.spanPosX;
            case SidePosition.Bottom:
                return -this.model.baseMaterial.spanNegY;
        }
    }

    private getBaseInterval(sidePosition: SidePosition): [number, number] {
        if (sidePosition == SidePosition.Left || sidePosition == SidePosition.Right) {
            return [-this.model.baseMaterial.spanNegY, this.model.baseMaterial.spanPosY];
        }
        return [-this.model.baseMaterial.spanNegX, this.model.baseMaterial.spanPosX];
    }

    private subtractIntervals(base: [number, number], intervals: Interval[]): [number, number][] {
        const sortedIntervals = intervals
            .map(i => [i.origin - i.length / 2, i.origin + i.length / 2] as [number, number])
            .sort((a, b) => a[0] - b[0]);

        const result: [number, number][] = [];
        let currentStart = base[0];

        for (const [start, end] of sortedIntervals) {
            if (end <= base[0] || start >= base[1]) {
                continue;
            }

            const clippedStart = Math.max(start, base[0]);
            const clippedEnd = Math.min(end, base[1]);

            if (currentStart < clippedStart) {
                result.push([currentStart, clippedStart]);
            }

            currentStart = Math.max(currentStart, clippedEnd);
        }

        if (currentStart < base[1]) {
            result.push([currentStart, base[1]]);
        }

        return result;
    }

    private getSideName(sidePosition: SidePosition) {
        switch (sidePosition) {
            case SidePosition.Left:
                return 'Left';
            case SidePosition.Top:
                return 'Top';
            case SidePosition.Right:
                return 'Right';
            case SidePosition.Bottom:
                return 'Bottom';
        }
    }

    private getOpenings(): OpeningInfo[] {
        return [
            { length: this.model.baseMaterial.punchOpening1Length, width: this.model.baseMaterial.punchOpening1Width, originX: this.model.baseMaterial.punchOpening1OriginX, originY: this.model.baseMaterial.punchOpening1OriginY },
            { length: this.model.baseMaterial.punchOpening2Length, width: this.model.baseMaterial.punchOpening2Width, originX: this.model.baseMaterial.punchOpening2OriginX, originY: this.model.baseMaterial.punchOpening2OriginY },
            { length: this.model.baseMaterial.punchOpening3Length, width: this.model.baseMaterial.punchOpening3Width, originX: this.model.baseMaterial.punchOpening3OriginX, originY: this.model.baseMaterial.punchOpening3OriginY },
        ];
    }

    private createInterval(origin: number, length: number, position: number): Interval {
        return {
            origin: origin,
            length: length,
            position: position
        };
    }

    private createMaterial(name: string, color: Color3): StandardMaterial {
        const material = this.cache.materialCache.create(name, () => new StandardMaterial(name, this.scene));
        material.diffuseColor = color;
        return material;
    }

    private ensureBaseMaterialTop(): void {
        this.disposeOldTopMesh();

        const shape = this.createBaseShapeForTop();
        const holes = this.createOpeningShapes();
        const thickness = this.model.baseMaterial.thickness;

        this.materialMeshTop = CreatePolygon(
            'MaterialTop',
            { shape, holes },
            this.scene,
            earcut
        );

        if (this.materialMeshTop != null) {
            this.configureMesh(this.materialMeshTop, thickness);
            this.materialMeshTop.parent = this.cache.meshCache.getCompressionMemberTransformNode(
                this.model.baseMaterial.compressionMemberId
            );
        }
    }

    private ensureBaseMaterialBottom(): void {
        this.disposeOldBottomMesh();

        const shape = this.createBaseShapeForBottom();

        const holesOpenings = this.createOpeningShapes(true);
        const holesRecess = this.createRecessShapes();
        const holes = holesOpenings.concat(holesRecess);

        this.materialMeshBottom = CreatePolygon(
            'MaterialBottom',
            { shape, holes },
            this.scene,
            earcut
        );

        if (this.materialMeshBottom != null) {
            this.configureMesh(this.materialMeshBottom, -this.model.baseMaterial.thickness);
            this.materialMeshBottom.rotation.x = Math.PI;
            this.materialMeshBottom.parent = this.cache.meshCache.getCompressionMemberTransformNode(
                this.model.baseMaterial.compressionMemberId
            );
        }
    }

    private createBaseShapeForTop(): Vector3[] {
        return [
            new Vector3(-this.model.baseMaterial.spanNegX, 0, this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, -this.model.baseMaterial.spanNegY),
            new Vector3(-this.model.baseMaterial.spanNegX, 0, -this.model.baseMaterial.spanNegY)
        ];
    }

    private createBaseShapeForBottom(): Vector3[] {
        return [
            new Vector3(-this.model.baseMaterial.spanNegX, 0, -this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, -this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, this.model.baseMaterial.spanNegY),
            new Vector3(-this.model.baseMaterial.spanNegX, 0, this.model.baseMaterial.spanNegY)
        ];
    }

    private createOpeningShapes(isBottom = false): Vector3[][] {
        const openings = this.getOpenings();

        return openings.map(opening => this.getRectangleShape(opening, isBottom));
    }

    private createRecessShapes(): Vector3[][] {
        this.recessCylinders.forEach(x => x.dispose());
        this.recessHoleBottoms.forEach(x => x.dispose());

        if (this.model.punchPostInstalledElement.depthOfRecess === 0) {
            return [];
        }

        const anchorDiameter = getPunchWasherDiameter(this.model);

        // get points from all perimeters as single array
        const recessPoints = this.model.punchPostInstalledElement.perimeters.flatMap(p => p.points);

        const recessHoles: Vector3[][] = [];
        const punchOffset = getPunchSpanOffset(this.model);

        for (const recessPoint of recessPoints) {
            recessHoles.push(this.getCircleShape(new Vector3(recessPoint.x - punchOffset.x, 0, -recessPoint.y + punchOffset.y), anchorDiameter));
            this.createRecessCyliner(recessPoint, anchorDiameter);
        }
        return recessHoles;
    }
    private createRecessCyliner(pos: Vector2, anchorDiameter: number): void {
        const cylinder = CreateCylinder(`PunchBaseMaterial_recess_cylinder_${pos.x}-${pos.y}`, {
            tessellation: 24, height: this.model.punchPostInstalledElement.depthOfRecess, sideOrientation: Mesh.BACKSIDE, cap: Mesh.NO_CAP
        }, this.scene);
        cylinder.material = this.baseMaterialMaterial;
        cylinder.position = translateKernelPointTo3dPoint(this.model, pos, getRecessHoleZPosition(this.model));
        cylinder.scaling = new Vector3(anchorDiameter, 1, anchorDiameter);
        cylinder.rotation.x = Math.PI / 2;
        cylinder.setEnabled(true);
        cylinder.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        this.recessCylinders.push(cylinder);

        const holeShapeBottom = this.getCircleShape(Vector3.Zero(), 1);
        const holeBottomMesh = CreatePolygon(`PunchBaseMaterial_recess_hole_${pos.x}-${pos.y}`, { shape: holeShapeBottom, sideOrientation: Mesh.BACKSIDE }, this.scene, earcut);
        holeBottomMesh.material = this.baseMaterialMaterialDark;
        holeBottomMesh.position = translateKernelPointTo3dPoint(this.model, pos, getWasherZPosition(this.model));
        holeBottomMesh.scaling = new Vector3(anchorDiameter, 1, anchorDiameter);
        holeBottomMesh.rotation.x = -Math.PI / 2;
        holeBottomMesh.setEnabled(true);
        holeBottomMesh.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        this.recessHoleBottoms.push(holeBottomMesh);
    }

    private getRectangleShape({ length, width, originX, originY }: OpeningInfo, isBottom: boolean): Vector3[] {
        const centerZ = isBottom ? -originY : originY;
        const halfLength = length / 2;
        const halfWidth = width / 2;

        return [
            new Vector3(originX - halfLength, 0, centerZ - halfWidth),
            new Vector3(originX + halfLength, 0, centerZ - halfWidth),
            new Vector3(originX + halfLength, 0, centerZ + halfWidth),
            new Vector3(originX - halfLength, 0, centerZ + halfWidth)
        ];
    }

    private configureMesh(mesh: Mesh, thickness: number): void {
        mesh.setEnabled(true);
        mesh.material = this.baseMaterialMaterial;
        this.baseMaterialMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;
        mesh.alphaIndex = baseMaterialAlphaIndex;
        mesh.position = new Vector3(0, thickness / 2, 0);
    }

    private createSideMesh(sideInfo: SideInfo, intervalId: number): Mesh {
        const baseName = 'baseMaterialSide' + this.getSideName(sideInfo.sidePosition) + intervalId;
        const mesh = this.cache.meshCache.create(baseName, () => CreatePlane(baseName, {}, this.scene));
        const materialName = baseName + 'Material';
        const material = this.cache.materialCache.create(materialName, () => new StandardMaterial(materialName, this.scene));

        material.diffuseColor = Color3.FromHexString('#cbcabf');
        material.alpha = this.model.visibilityModel.TransparentConcrete ? 0.75 : 1;

        mesh.material = material;
        const interval = sideInfo.intervals[intervalId];
        mesh.scaling.x = interval.length;
        mesh.scaling.y = this.model.baseMaterial.thickness;
        mesh.rotation.y = this.getOrientation(sideInfo.sidePosition);
        if (sideInfo.sidePosition === SidePosition.Left || sideInfo.sidePosition === SidePosition.Right) {
            mesh.position.set(interval.position, 0, interval.origin);
        }
        else {
            mesh.position.set(interval.origin, 0, interval.position);
        }

        // rotation
        mesh.parent = this.cache.meshCache.getCompressionMemberTransformNode(
            this.model.baseMaterial.compressionMemberId
        );

        return mesh;
    }

    private getOrientation(sidePosition: SidePosition): number {
        switch (sidePosition) {
            case SidePosition.Left:
                return Math.PI / 2;
            case SidePosition.Top:
                return Math.PI;
            case SidePosition.Right:
                return -Math.PI / 2;
            case SidePosition.Bottom:
                return 0;
        }
    }

    private getCircleShape(position: Vector3, size: number): Vector3[] {
        const circlePoints: Vector3[] = [];
        this.cache.commonCache.getCirclePoints(numberOfSides, 1).forEach((point) => {
            circlePoints.push(new Vector3(position.x + point.x * size, position.y, position.z + point.y * size));
        });
        return circlePoints;
    }

    private openingNumberingText(openingTextArray: Text2D[], opening: number, height: number, originX: number, originY: number, openingId: string) {
        let openingText = openingTextArray[opening];

        if (!openingText) {
            openingText = this.createText2D();
            openingText.setColor(Color3.Black());
            openingText.setAlpha(0.7);
            openingTextArray[opening] = openingText;
        }

        openingText.setText(openingId);
        const position = openingText.mesh!.position;
        position.x = originX;
        position.y = height;
        position.z = originY;
    }

    private ensureOpeningNumbering() {
        const openingsCount = this.model.baseMaterial.openingsNumberId - 1;

        for (let i = 0; i < openingsCount; i++) {
            const openingId = `O${i + 1}`;

            const originX = (this.model.baseMaterial as unknown as Record<string, number>)[`punchOpening${i + 1}OriginX`];
            const originY = (this.model.baseMaterial as unknown as Record<string, number>)[`punchOpening${i + 1}OriginY`];

            if (!this.model.visibilityModel.TransparentConcrete) {
                const heightTop = this.model.baseMaterial.thickness / 2 + OpeningNumberHeightOffset;
                this.openingNumberingText(this.openingNumberingTextTop, i, heightTop, originX, originY, openingId);
            }

            const heightBottom = -this.model.baseMaterial.thickness / 2 - OpeningNumberHeightOffset;
            this.openingNumberingText(this.openingNumberingTextBottom, i, heightBottom, originX, originY, openingId);
        }
    }

    private disposeOldBottomMesh(): void {
        if (this.materialMeshBottom) {
            this.materialMeshBottom.dispose();
            this.materialMeshBottom = null;
        }
    }
    private disposeOldTopMesh(): void {
        if (this.materialMeshTop) {
            this.materialMeshTop.dispose();
            this.materialMeshTop = null;
        }
    }
    public override dispose(): void {
        this.disposeOldBottomMesh();
        this.disposeOldTopMesh();
        this.openingNumberingTextTop.forEach(textTop => textTop?.dispose());
        this.openingNumberingTextBottom.forEach(textBottom => textBottom?.dispose());
        super.dispose();
    }
}
