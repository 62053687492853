import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector.js';
import { GLTemp } from './cache/gl-temp.js';
const glTemp = new GLTemp({
  vector3: 6
});
export class Plane {
  static zero = Vector3.Zero();
  static transform = Matrix.Zero();
  top;
  right;
  constructor(top, right) {
    this.top = top;
    this.right = right;
  }
  static isVisible(scene, top, right, transform) {
    Plane.transform.copyFrom(scene.getTransformMatrix());
    // transform matrix
    if (transform != null) {
      transform.multiplyToRef(Plane.transform, Plane.transform);
    }
    // transform to screen space
    const bottomLeft = glTemp.vector3[0];
    Vector3.TransformCoordinatesToRef(Plane.zero, Plane.transform, bottomLeft);
    const bottomRight = glTemp.vector3[1];
    Vector3.TransformCoordinatesToRef(right, Plane.transform, bottomRight);
    const topLeft = glTemp.vector3[2];
    Vector3.TransformCoordinatesToRef(top, Plane.transform, topLeft);
    // get the two view vectors
    const viewRight = glTemp.vector3[3];
    bottomRight.subtractToRef(bottomLeft, viewRight);
    const viewTop = glTemp.vector3[4];
    topLeft.subtractToRef(bottomLeft, viewTop);
    // cross
    const viewFront = glTemp.vector3[5];
    Vector3.CrossToRef(viewRight, viewTop, viewFront);
    // is visible
    return viewFront.z > 0;
  }
  isVisible(scene) {
    return Plane.isVisible(scene, this.top, this.right);
  }
}
