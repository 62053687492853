import {
    Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, Input, NgZone, OnInit, ViewChild
} from '@angular/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import {
    IDesignSectionExportComponentInput, IDesignSectionExportItem, Loading
} from '@profis-engineering/pe-ui-common/entities/design-section-export';
import { UrlPath } from '@profis-engineering/pe-ui-common/entities/module-constants';
import { formatKeyValue } from '@profis-engineering/pe-ui-common/helpers/string-helper';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

import { environment } from '../../../environments/environment';
import { L10nDirective } from '../../directives/l10n.directive';
import {
    DrillingAidId, DrillingTypeId, HoleTypeId, PostInstalledReinforcementDesignId, RegionId
} from '../../ids';
import { L10nPipe } from '../../pipes/l10n.pipe';
import { BrowserService } from '../../services/browser.service';
import { DataService } from '../../services/data.service';
import {
    DesignDetails, DesignService, designTypeSwitch, ProjectDesign, PropertyIdValue,
    StrengthCalculationResult, StrengthDesignDetails, StrengthProperties, UtilizationResults,
    UtilizationType
} from '../../services/design.service';
import { DocumentService } from '../../services/document.service';
import { ImportService } from '../../services/import.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { TrackingDetails } from '../../services/tracking.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { getSpriteAsIconStyle } from '../../sprites';
import { ScreenshotSettings } from '../../web-gl/gl-model';

// TODO FILIP: why do we have to copy this component?
@Component({
    selector: 'app-design-section',
    templateUrl: './design-section.component.html',
    styleUrls: ['./design-section.component.scss'],
    imports: [
        L10nDirective,
        NgbTooltip,
        L10nPipe
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DesignSectionComponent implements OnInit {
    @Input()
    public openExport = () => this.generateReport();

    @Input()
    public openFile = () => this.selectOpenFile();

    @Input()
    public createScreenshot3D!: (screenshotSettings: ScreenshotSettings) => Promise<string>;

    @Input()
    public propertyChange!: (propertyChanges: PropertyIdValue[]) => Promise<void>;

    @Input()
    public designDetails!: DesignDetails;

    @Input()
    public trackingDetails!: TrackingDetails;

    @ViewChild('openDesignInput')
    public openDesigninputElement!: ElementRef<HTMLInputElement>;

    public loading = false;
    public duplicating = false;
    public openDesign = false;

    private designSectionExportInput!: IDesignSectionExportComponentInput;

    constructor(
        public localizationService: LocalizationService,
        private readonly modalService: ModalService,
        private readonly documentService: DocumentService,
        private readonly designService: DesignService,
        private readonly importService: ImportService,
        private readonly browserService: BrowserService,
        private readonly userService: UserService,
        private readonly userSettingsService: UserSettingsService,
        private readonly ngZone: NgZone,
        public dataService: DataService,
        public unitService: UnitService,
    ) { }

    public get isCalculationValid() {
        return this.designService.isCalculationValid(this.designDetails.calculateResult);
    }

    public get disabled() {
        return this.designDetails.isTemplate;
    }

    public ngOnInit(): void {
        this.setDesignSectionExportInput();
    }

    public openDesignSectionExport() {
        this.modalService.openDesignSectionExport(this.designSectionExportInput);
    }

    public generateReport() {
        this.loading = true;
        this.openGenerateReportPopup();
    }

    public selectOpenFile() {
        if (this.openDesign) {
            return;
        }

        if (this.openDesigninputElement.nativeElement.value == null || this.openDesigninputElement.nativeElement.value == '') {
            setTimeout(() => this.openDesigninputElement.nativeElement.click());
        }
    }

    public async importFileSelected() {
        if (this.openDesigninputElement.nativeElement.value != null
            && this.openDesigninputElement.nativeElement.value != ''
            && this.openDesigninputElement.nativeElement.files != null) {
            const file = this.openDesigninputElement.nativeElement.files[0];

            // clear input
            this.openDesigninputElement.nativeElement.value = '';

            await this.saveChangesBeforeImport(file);
        }
    }

    public async duplicateDesign(): Promise<void> {
        if (this.duplicating) {
            return;
        }

        this.duplicating = true;
        this.trackingDetails.counters.designDuplicate++;
        this.trackingServiceChange();

        try {
            // just create a design on document service and open it in a new tab
            // it's fine if it gets locked since when you close the design on the other tab it will unlock
            // no tracking open here
            const documentDesign = await this.designService.documentServiceCreateDesign({
                projectDesign: this.designDetails.projectDesign,
                designName: this.designDetails.designName!,
                projectId: this.designDetails.projectId!,
                designStandardId: this.designDetails.properties.designStandardId,
                designTypeId: this.designDetails.designTypeId,
                regionId: this.designDetails.regionId
            });

            const openUrl = `${environment.baseUrl.replace(/\/+$/, '')}${UrlPath.main}${documentDesign.id}`;
            window.open(openUrl, '_blank');
        }
        finally {
            this.duplicating = false;
        }
    }

    private async saveChangesBeforeImport(projectDesign: File | Blob, name?: string) {
        await this.import(projectDesign, name);
    }

    private async import(projectDesign: File | Blob, name?: string): Promise<void> {
        // projectDesign can contain a design for other modules

        this.openDesign = true;
        this.trackingDetails.counters.designImport++;
        this.trackingServiceChange();

        try {
            const project = this.documentService.findProjectById(this.designDetails.projectId!);

            // TODO FILIP: importService.import accepts a nullable name - wrong definition in common
            await this.importService.import(project, this.userService.design, projectDesign, name!, true);
        }
        finally {
            this.openDesign = false;
        }
    }

    private createFileToDeviceExportItem(): IDesignSectionExportItem {
        return {
            condition: () => true,
            buttonClick: () => {
                NgZone.assertNotInAngularZone();
                return this.ngZone.run(() => {
                    this.trackingDetails.counters.designExport++;
                    this.trackingServiceChange();

                    // no await needed
                    this.downloadDesignFile(this.designDetails.projectDesign, this.designDetails.projectName!, this.designDetails.designName!)
                        .catch((error: unknown) => console.error(error));

                    return true;
                });
            },
            buttonDisabled: () => this.disabled,
            buttonLoading: Loading.localFile,
            imageClass: 'sprite-profis-file',
            imageStyle: getSpriteAsIconStyle('sprite-profis-file'),
            message: this.translate('Agito.Hilti.Profis3.DesignSectionExport.ProfisFile'),
            description: this.translate('Agito.Hilti.Profis3.DesignSectionExport.ProfisFile.Description'),
            hasLoading: false
        };
    }

    private trackingServiceChange() {
        // no await needed
        this.designService
            .trackOnDesignOrTemplateChange({
                designDetails: this.designDetails,
                trackingDetails: this.trackingDetails,

                immediateRequest: false
            })
            .catch((error: unknown) => console.error(error));
    }

    private async downloadDesignFile(projectDesign: ProjectDesign, projectName: string, designName: string): Promise<void> {
        const projectDesignJson = JSON.stringify(projectDesign, undefined, 2);
        const projectDesignBlob = new Blob([projectDesignJson], {
            type: 'application/json'
        });

        const fileNameFirstPart = projectName;
        const fileNameSecondPart = designName;
        const fileName = `${fileNameFirstPart}_${fileNameSecondPart}.pe`;

        await this.browserService.downloadBlob(projectDesignBlob, fileName, false, false);
    }

    private openGenerateReportPopup() {
        this.modalService.openExportReport({
            createScreenshot3D: this.createScreenshot3D,
            propertyChange: this.propertyChange
        });

        this.loading = false;
    }

    private setDesignSectionExportInput() {
        this.designSectionExportInput = {
            exportItems: [
                // PROFIS file to device
                this.createFileToDeviceExportItem(),
                // TODO TEAM: add other exports
                // Ausscheibungs text or Specification text
                this.ausscheibungsTextExportItem()
            ]
        };
    }

    public translate(key: string) {
        return this.localizationService.getString(key);
    }

    private ausscheibungsTextExportItem(): IDesignSectionExportItem {
        return {
            condition: () => true,
            tooltip: this.translate('SP.Main.Region.Export.SpecificationText.Description'),
            tooltipDisabled: this.specificationTextTooltipDisabled,
            buttonClick: () => {
                this.trackingDetails.counters.specificationTextExport++;
                this.trackingServiceChange();

                let ret = true;

                if (this.isCustomSpecificationText) {
                    const tab = window.open(this.onlineTenderTextsUrl, '_blank');
                    if (tab != null) {
                        tab.focus();
                    }

                    ret = false;
                }
                else {
                    const specificationText = this.getDesignSpecificationText('SP.Main.Region.Export.SpecificationText.Text');

                    this.modalService.openSpecificationText(specificationText ?? '');
                }

                return ret;
            },
            buttonDisabled: () => (!this.showSpecificationTextButton() || !this.isCalculationValid) && !this.isCustomSpecificationText,
            buttonLoading: this.isCustomSpecificationText ? undefined : Loading.specificationText,
            imageClass: 'sprite-specification-text',
            imageStyle: getSpriteAsIconStyle('sprite-specification-text'),
            message: this.specificationText,
            description: this.specificationTextDescription,
            hasLoading: false
        };
    }

    public get onlineTenderTextsUrl() {
        return this.userSettingsService.globalRegion.onlineTenderTextsUrl;
    }

    public get isCustomSpecificationText() {
        if (this.userSettingsService.globalRegion.id == RegionId.Germany as number ||
            this.userSettingsService.globalRegion.id == RegionId.Austria as number) {
            return true;
        }

        return false;
    }

    public get specificationText() {
        if (this.isCustomSpecificationText) {
            return this.translate('SP.Main.Region.Export.Link.OnlineTenderTextsUrl');
        }

        return this.translate('SP.Main.Region.Export.SpecificationText');
    }

    public get specificationTextDescription() {
        if (!this.isCustomSpecificationText) {
            return this.translate('SP.Main.Region.Export.SpecificationText.Description');
        }

        return '';
    }

    public get specificationTextTooltipDisabled() {
        return this.isCustomSpecificationText;
    }

    public getDesignSpecificationText(key: string): string {
        return designTypeSwitch(this.designDetails.designTypeId,
            () => this.strengthSpecificationText(key),
            () => this.punchSpecificationText()
        );
    }

    public strengthSpecificationText(key: string): string {
        const strengthDesignDetails = this.designDetails as StrengthDesignDetails;
        const calculateResult = this.designDetails.calculateResult! as StrengthCalculationResult;
        // eslint-disable-next-line @typescript-eslint/no-deprecated
        const utilizationResult = calculateResult?.utilizationResults;

        let finalSpecificationText = '';
        const translationFormat = this.localizationService.getString(key);
        const zonesNumberId = strengthDesignDetails.properties.zonesNumberId;
        const approvalNumber = strengthDesignDetails.properties.approval?.number;
        const approvalType = this.approvalType(strengthDesignDetails.properties.postInstalledReinforcementDesignId);
        const productType = this.getFastenerFamilyRegionalName(this.designDetails.properties.fastenerFamilyId, this.designDetails.regionId);
        const productSize = this.dataService.fastenersById[this.designDetails.properties.fastenerId].name;
        const drillingAid = this.drillingAid(strengthDesignDetails.properties.drillingAidId);
        const drillingMethod = this.drillingMethod(strengthDesignDetails.properties.drillingTypeId);
        const holeType = this.holeType(strengthDesignDetails.properties.holeTypeId);
        const holeDia = this.dataService.fastenersById[this.designDetails.properties.fastenerId].holeDia;

        for (let i = 1; i <= zonesNumberId; i++) {
            if (this.isDefinedStrengtheningElementForZone(i, strengthDesignDetails.properties)) {
                const zoneSpacingXStr = this.getPostInstalledSpacingX(i, strengthDesignDetails.properties);
                const zoneSpacingYStr = this.getPostInstalledSpacingY(i, strengthDesignDetails.properties);
                const zoneSpacingX = parseFloat(zoneSpacingXStr);
                const zoneSpacingY = parseFloat(zoneSpacingYStr);

                finalSpecificationText += formatKeyValue(translationFormat, {
                    zone_id: i.toString(),
                    h_ef: this.getZoneDrillLength('Zone ' + i.toString(), utilizationResult) ?? '-',
                    spacing: this.generateSpacingText(zoneSpacingX, zoneSpacingY, zoneSpacingXStr, zoneSpacingYStr),
                    product_type: productType,
                    product_size: productSize,
                    approval: approvalNumber!,
                    approval_type: approvalType,
                    drilling_aid: drillingAid,
                    drilling_method: drillingMethod,
                    d_0: this.formatValue(UnitGroup.Length, holeDia),
                    hole_type: holeType,
                    no_of_elements: this.getNumberOfAnchors('Zone ' + i.toString(), utilizationResult) ?? '-'
                }) + '\n';
            }
        }

        return finalSpecificationText;
    }

    public generateSpacingText(zoneSpacingX: number, zoneSpacingY: number, zoneSpacingXStr: string, zoneSpacingYStr: string): string {
        let spacingTranslation = '';
        let spacingText = '';

        if (zoneSpacingX > 0 && zoneSpacingY > 0) {
            spacingTranslation = this.localizationService.getString('SP.Main.Region.Export.SpecificationText.Text.SpacingXY');
            spacingText = spacingTranslation.replace('{s_x}', zoneSpacingXStr).replace('{s_y}', zoneSpacingYStr);
        }
        else if (zoneSpacingX > 0) {
            spacingTranslation = this.localizationService.getString('SP.Main.Region.Export.SpecificationText.Text.SpacingX');
            spacingText = spacingTranslation.replace('{s_x}', zoneSpacingXStr);
        }
        else if (zoneSpacingY > 0) {
            spacingTranslation = this.localizationService.getString('SP.Main.Region.Export.SpecificationText.Text.SpacingY');
            spacingText = spacingTranslation.replace('{s_y}', zoneSpacingYStr);
        }

        return spacingText ? ' ' + spacingText : spacingText;
    }

    public punchSpecificationText(): string {
        return '';
    }

    public getIsAnyDefinedStrengtheningElement(): boolean {
        const strengthProperties = this.designDetails.properties as StrengthProperties;

        for (let i = 1; i <= strengthProperties.zonesNumberId; i++) {
            if (this.isDefinedStrengtheningElementForZone(i, strengthProperties)) {
                return true;
            }
        }

        return false;
    }

    public showSpecificationTextButton(): boolean {
        return designTypeSwitch(this.designDetails.designTypeId,
            () => this.getIsAnyDefinedStrengtheningElement(),
            () => true
        );
    }

    public isDefinedStrengtheningElementForZone(zoneNumber: number, strengthProperties: StrengthProperties): boolean {
        switch (zoneNumber) {
            case 1:
                return strengthProperties.zone1StrengtheningElementDefinition;
            case 2:
                return strengthProperties.zone2StrengtheningElementDefinition ?? false;
            case 3:
                return strengthProperties.zone3StrengtheningElementDefinition ?? false;
            case 4:
                return strengthProperties.zone4StrengtheningElementDefinition ?? false;
            case 5:
                return strengthProperties.zone5StrengtheningElementDefinition ?? false;
            case 6:
                return strengthProperties.zone6StrengtheningElementDefinition ?? false;
            case 7:
                return strengthProperties.zone7StrengtheningElementDefinition ?? false;
            case 8:
                return strengthProperties.zone8StrengtheningElementDefinition ?? false;
            default:
                throw new Error('Invalid zone number.');
        }
    }

    public getFastenerFamilyRegionalName(fastenerFamilyId: number, regionId: number): string {
        const fastenerFamily = this.dataService.fastenerFamiliesById[fastenerFamilyId];

        return fastenerFamily.regionalNames?.[regionId] ?? fastenerFamily.name;
    }

    public drillingAid(drillingAidId: DrillingAidId) {
        switch (drillingAidId) {
            case DrillingAidId.NoDrillingAid:
                return this.translate('SP.DrillingAid.NoDrillingAid');
            case DrillingAidId.DrillingAidIsUsed:
                return this.translate('SP.DrillingAid.DrillingAidIsUsed');
            default:
                throw new Error('Unknown drilling aid id.');
        }
    }

    public drillingMethod(drillingTypeId: DrillingTypeId) {
        switch (drillingTypeId) {
            case DrillingTypeId.HammerDrilling:
                return this.translate('SP.DrillingType.HammerDrilling');
            case DrillingTypeId.HammerDrillingHollow:
                return this.translate('SP.DrillingType.HammerDrillingHollow');
            case DrillingTypeId.CompressedAirDrilling:
                return this.translate('SP.DrillingType.CompressedAirDrilling');
            default:
                throw new Error('Unknown drilling type id.');
        }
    }

    public holeType(holeTypeId: HoleTypeId) {
        switch (holeTypeId) {
            case HoleTypeId.DryConcrete:
                return this.translate('SP.HoleType.DryConcrete');
            case HoleTypeId.WaterSaturatedConcrete:
                return this.translate('SP.HoleType.WaterSaturatedConcrete');
            case HoleTypeId.WaterFilledHole:
                return this.translate('SP.HoleType.WaterFilledHole');
            default:
                throw new Error('Unknown hole type id.');
        }
    }

    public approvalType(postInstalledReinforcementDesignId: PostInstalledReinforcementDesignId) {
        if (postInstalledReinforcementDesignId === PostInstalledReinforcementDesignId.DiBtApproval) {
            return this.translate('SP.ApprovalType.DIBtApproval');
        }
        else {
            throw new Error('Unknown post installed reinforcement design id.');
        }
    }

    public formatValue(unitGroup: UnitGroup, value: number | undefined): string {
        if (value == null) {
            return '-';
        }

        const defaultUnit = this.unitService.getDefaultUnit(unitGroup);
        const internalUnit = this.unitService.getInternalUnit(unitGroup);
        const defaultPrecision = this.unitService.getPrecision(defaultUnit);

        const valueToFormat = this.unitService.convertUnitValueArgsToUnit(value, internalUnit, defaultUnit, true);

        return this.unitService.formatNumber(valueToFormat, defaultPrecision) + ' ' + this.unitService.formatUnit(defaultUnit);
    }

    public getZoneDrillLength(zoneNumber: string, utilizationResults: UtilizationResults[] | undefined): string | undefined {
        const zoneResults = utilizationResults!.find(x => x.zoneId == zoneNumber);
        if (zoneResults === undefined) {
            return undefined;
        }

        if (zoneResults.utilizationItems === undefined) {
            return undefined;
        }

        const drillLength = zoneResults.utilizationItems.find(x => x.utilizationType == UtilizationType.StrengthenedMemberDrillLength)?.actualValue;

        if (drillLength == null) {
            return '-';
        }

        return this.formatValue(UnitGroup.Length, drillLength);
    }


    public getNumberOfAnchors(zoneNumber: string, utilizationResults: UtilizationResults[] | undefined): string | undefined {
        const zoneResults = utilizationResults!.find(x => x.zoneId == zoneNumber);
        if (zoneResults === undefined) {
            return undefined;
        }

        if (zoneResults.utilizationItems === undefined) {
            return undefined;
        }

        const numberOfAnchors = zoneResults.utilizationItems.find(x => x.utilizationType == UtilizationType.StrengthenedMemberNumberOfStrengtheningElements)?.actualValue;

        if (numberOfAnchors == null) {
            return '-';
        }

        return numberOfAnchors.toString();
    }

    public getPostInstalledSpacingX(zoneNumber: number, strengthProperties: StrengthProperties): string {
        const availableSpaceX = this.getZoneLength(zoneNumber, strengthProperties) - 2 * this.getZoneMinimumEdgeDistanceX(zoneNumber, strengthProperties);

        const rowCount = Math.floor(availableSpaceX / this.getZoneSpacingX(zoneNumber, strengthProperties)) + 1;

        // in case there is only a single row of strengthening elements in this direction, the value should be “0”
        if (rowCount == 1) {
            return this.formatValue(UnitGroup.Length, 0);
        }

        return this.formatValue(UnitGroup.Length, this.getZoneSpacingX(zoneNumber, strengthProperties));
    }

    public getPostInstalledSpacingY(zoneNumber: number, strengthProperties: StrengthProperties): string {
        const availableSpaceY = this.getZoneWidth(zoneNumber, strengthProperties) - 2 * this.getZoneMinimumEdgeDistanceY(zoneNumber, strengthProperties);

        const columnCount = Math.floor(availableSpaceY / this.getZoneSpacingY(zoneNumber, strengthProperties)) + 1;

        // in case there is only a single row of strengthening elements in this direction, the value should be “0”
        if (columnCount == 1) {
            return this.formatValue(UnitGroup.Length, 0);
        }

        return this.formatValue(UnitGroup.Length, this.getZoneSpacingY(zoneNumber, strengthProperties));
    }

    public getZoneLength(zoneNumber: number, strengthProperties: StrengthProperties): number {
        switch (zoneNumber) {
            case 1:
                return strengthProperties.zone1Length;
            case 2:
                return strengthProperties.zone2Length ?? 0;
            case 3:
                return strengthProperties.zone3Length ?? 0;
            case 4:
                return strengthProperties.zone4Length ?? 0;
            case 5:
                return strengthProperties.zone5Length ?? 0;
            case 6:
                return strengthProperties.zone6Length ?? 0;
            case 7:
                return strengthProperties.zone7Length ?? 0;
            case 8:
                return strengthProperties.zone8Length ?? 0;
            default:
                throw new Error('Invalid zone number.');
        }
    }

    public getZoneWidth(zoneNumber: number, strengthProperties: StrengthProperties): number {
        switch (zoneNumber) {
            case 1:
                return strengthProperties.zone1Width ?? 0;
            case 2:
                return strengthProperties.zone2Width ?? 0;
            case 3:
                return strengthProperties.zone3Width ?? 0;
            case 4:
                return strengthProperties.zone4Width ?? 0;
            case 5:
                return strengthProperties.zone5Width ?? 0;
            case 6:
                return strengthProperties.zone6Width ?? 0;
            case 7:
                return strengthProperties.zone7Width ?? 0;
            case 8:
                return strengthProperties.zone8Width ?? 0;
            default:
                throw new Error('Invalid zone number.');
        }
    }

    public getZoneSpacingX(zoneNumber: number, strengthProperties: StrengthProperties): number {
        switch (zoneNumber) {
            case 1:
                return strengthProperties.zone1SpacingX ?? 0;
            case 2:
                return strengthProperties.zone2SpacingX ?? 0;
            case 3:
                return strengthProperties.zone3SpacingX ?? 0;
            case 4:
                return strengthProperties.zone4SpacingX ?? 0;
            case 5:
                return strengthProperties.zone5SpacingX ?? 0;
            case 6:
                return strengthProperties.zone6SpacingX ?? 0;
            case 7:
                return strengthProperties.zone7SpacingX ?? 0;
            case 8:
                return strengthProperties.zone8SpacingX ?? 0;
            default:
                throw new Error('Invalid zone number.');
        }
    }

    public getZoneSpacingY(zoneNumber: number, strengthProperties: StrengthProperties): number {
        switch (zoneNumber) {
            case 1:
                return strengthProperties.zone1SpacingY ?? 0;
            case 2:
                return strengthProperties.zone2SpacingY ?? 0;
            case 3:
                return strengthProperties.zone3SpacingY ?? 0;
            case 4:
                return strengthProperties.zone4SpacingY ?? 0;
            case 5:
                return strengthProperties.zone5SpacingY ?? 0;
            case 6:
                return strengthProperties.zone6SpacingY ?? 0;
            case 7:
                return strengthProperties.zone7SpacingY ?? 0;
            case 8:
                return strengthProperties.zone8SpacingY ?? 0;
            default:
                throw new Error('Invalid zone number.');
        }
    }

    public getZoneMinimumEdgeDistanceX(zoneNumber: number, strengthProperties: StrengthProperties): number {
        switch (zoneNumber) {
            case 1:
                return strengthProperties.zone1MinimumEdgeDistanceX ?? 0;
            case 2:
                return strengthProperties.zone2MinimumEdgeDistanceX ?? 0;
            case 3:
                return strengthProperties.zone3MinimumEdgeDistanceX ?? 0;
            case 4:
                return strengthProperties.zone4MinimumEdgeDistanceX ?? 0;
            case 5:
                return strengthProperties.zone5MinimumEdgeDistanceX ?? 0;
            case 6:
                return strengthProperties.zone6MinimumEdgeDistanceX ?? 0;
            case 7:
                return strengthProperties.zone7MinimumEdgeDistanceX ?? 0;
            case 8:
                return strengthProperties.zone8MinimumEdgeDistanceX ?? 0;
            default:
                throw new Error('Invalid zone number.');
        }
    }

    public getZoneMinimumEdgeDistanceY(zoneNumber: number, strengthProperties: StrengthProperties): number {
        switch (zoneNumber) {
            case 1:
                return strengthProperties.zone1MinimumEdgeDistanceY ?? 0;
            case 2:
                return strengthProperties.zone2MinimumEdgeDistanceY ?? 0;
            case 3:
                return strengthProperties.zone3MinimumEdgeDistanceY ?? 0;
            case 4:
                return strengthProperties.zone4MinimumEdgeDistanceY ?? 0;
            case 5:
                return strengthProperties.zone5MinimumEdgeDistanceY ?? 0;
            case 6:
                return strengthProperties.zone6MinimumEdgeDistanceY ?? 0;
            case 7:
                return strengthProperties.zone7MinimumEdgeDistanceY ?? 0;
            case 8:
                return strengthProperties.zone8MinimumEdgeDistanceY ?? 0;
            default:
                throw new Error('Invalid zone number.');
        }
    }
}
